// Customizable Area Start
import React from "react";
import {
  Box,Typography,Button,InputAdornment, Grid, Tabs,Divider, Tab, Switch, TextField, Menu, AppBar, Toolbar,
} from "@material-ui/core";
import { withStyles, Theme, createStyles, createTheme } from '@material-ui/core/styles';
import { styled, ThemeProvider } from "@material-ui/styles";
import { List, Search, ExpandMore } from '@material-ui/icons';

import ProjectPortfolioController, {
  Props
} from "./ProjectPortfolioController";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { addProjectFrame } from "./assets";
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

export default class ProjectPortfolio extends ProjectPortfolioController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const tabs = [
        { label: "All", index: 0 },
        { label: "Ongoing", index: 1 },
        { label: "Draft", index: 2 },
        { label: "Proposed", index: 3 },
        { label: "Completed", index: 4 },
        { label: "Paused", index: 5 },
    ];

    const renderTab = ({ label, index }: { label: string; index: number }) => (
        <Tab
            key={label}
            label={label}
            style={{
                minWidth: "auto",
                paddingInline: '20px',
                textTransform: "none",
                borderRadius: index === 4 ? "8px" : "25px",
                backgroundColor: this.state.activeTab === index ? "#E0EDF0" : "#F8FAFC",
                color: this.state.activeTab === index ? "#0F172A" : "#64748B",
                fontWeight: this.state.activeTab === index ? "bold" : 600,
                fontFamily: "Poppins",
            }}
        />
    );

    return (
      <ThemeProvider theme={theme}>
        <Box>
        <MyProfileHeader navigation={this.props.navigation} tabName={'Projects'}>
            <CustomTabs position="static" elevation={0}>
                <Toolbar
                    style={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        width: "94%",
                        backgroundColor: "#ffffff",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        alignSelf: "center",
                        marginTop: "24px",
                        borderRadius: "8px",
                    }}
                >
                    <Tabs
                        value={this.state.activeTab}
                        onChange={this.handleTabChange}
                        TabIndicatorProps={{
                            style: {
                                display: "none",
                            },
                        }}
                        variant="scrollable"
                        scrollButtons="auto"
                        style={{backgroundColor: "#F8FAFC", borderRadius: "18px", marginRight: "10px"}}
                    >
                        {tabs.map(renderTab)}
                    </Tabs>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <List style={{ color: '#334155' }} />
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 600, paddingInline: '4px' }}>List View</Typography>
                    </Box>
                    <Divider orientation="vertical" flexItem style={{ margin: "auto 5px", height: "40px" }} />
                    <Box flexGrow={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Typography style={{ color: "#334155", fontSize: '14px', fontWeight: 400, paddingInline: '4px' }}>Sort By:</Typography>
                        <CustomDropDown
                            id="outlined-select-currency-native"
                            select
                            value={this.state.sortTypeValue}
                            data-test-id={'sortFilter'}
                            onChange={this.handleSortByChange}
                            SelectProps={{
                                native: true,
                                IconComponent: ExpandMore,
                            }}
                        >
                            {this.state.sortType.map((option: { value: string; label: string }) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </CustomDropDown>
                    </Box>
                    <CustomSearchBox data-test-id={'searchInputText'} variant="outlined" id="outlined-basic" placeholder="Search here..." InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ color: '#94A3B8' }} />
                        </InputAdornment>
                      ),
                    }}/>
                </Toolbar>
            </CustomTabs>
            <Grid container spacing={2} style={{ padding: '15px', backgroundColor: '#F0F5F7',display:'contents' }}>
               <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center',marginTop:'50px'}}>
                <Box>
                  <img src={addProjectFrame} alt=""/>
                </Box>
                <Box>
                  <Typography style={{    fontFamily: 'Poppins',
                  color:'#0F172A',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.005em',
    textAlign: 'center',}}>No Project yet</Typography>
                </Box>
                <Box>
                <Typography style={{   
                  marginTop:'16px',
                  color:'#0F172A',
                   fontFamily: 'Poppins',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textAlign: 'center',}}>You haven't created or joined any projects. Start by creating a new project or joining an existing one.</Typography>
                </Box>
                <Box>
                <Button
  data-test-id="handleCreateNewProject"
  onClick={() => { this.handleCreateNewProject(); }}
  variant="contained"
  color="primary"
  style={{
    marginTop:'16px',
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px",
    textTransform: "capitalize",
  }}
>
  Create new project
</Button>
                
                </Box>
               </Box>
            </Grid>
    </MyProfileHeader>
            {/* <Loader loading={this.state.loading}/> */}
        </Box>
        </ThemeProvider>
    );
}

}

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    border: "1px solid #E2E8F0",
    width:"190px",
    height: "121px",  
    alignItems: "center",
    display: "grid",
    borderRadius: "8px",
  },
  "& .MuiList-padding": {
    paddingTop:0,
    paddingBottom:0
  },
  "& .MuiListItem-root": {
    margin: "0 4px",
  },
  "& .MuiListItem-button:hover": {
    backgroundColor: "#E0EDF0",
    borderRadius: "8px",
  }
});

const CustomSearchBox = styled((TextField))({
  width: '30%',
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: '#CBD5E1',
    },
    "&:hover fieldset": {
      borderColor: '#CBD5E1',
    },
    "&.Mui-focused fieldset": {
      borderColor: '#CBD5E1',
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "8px 0px",
    "&::placeholder": {
      color: '#94A3B8',
      opacity: 1,
    }
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(10px, 10px)"
  },
  "& .MuiFormLabel-root": {
    fontSize: '15px',
    color: '#94A3B8'
  }
});

const CustomTabs = styled((AppBar))({
  width: "100%",
  backgroundColor: "#F0F5F7",
  "& .MuiTab-root":{
    minHeight:'auto'
  },
  "& .MuiTabs-root":{
    minHeight:'auto'
  }
})
const CustomDropDown = styled((TextField))({
  "& .MuiInput-underline::before":{
    borderBottom:0,
  },
  "& .MuiInput-underline::after":{
    borderBottom:0,
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before":{
    borderBottom:0,
  },
  "& .MuiSelect-select:focus": {
    backgroundColor:'#fff',
    color:'#237182'
  },
  "& .MuiSelect-select.MuiSelect-select": {
    paddingBlock: "10px",
    color:'#237182',
    fontSize:'16px',
    fontWeight: 600
  },
  "& .MuiSvgIcon-root": {
    color: "#237182",
  }
})

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 42,
      height: 26,
      padding: 0,
      margin: theme.spacing(1),
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(16px)',
        color: theme.palette.common.white,
        '& + $track': {
          backgroundColor: '#325962',
          opacity: 1,
          border: 'none',
        },
      },
      '&$focusVisible $thumb': {
        color: '#52d869',
        border: '6px solid #ffffff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: 'grey',
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
  
)(Switch);

// Customizable Area End
