// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface RowData {
  id: string;
  name: string;
  members: number;
  dataTime: string;
}

interface MembersList {
  id: string | number;
  user_name: string;
  email: string;
  teams: string;
  role: string;
  status: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  toggle: boolean;
  checked: boolean;
  rows: RowData[];
  membersList: MembersList[];
  dialogOpen: boolean;
  selectedRow: RowData | null;
  anchorEl: HTMLElement | null;
  loading: boolean;
  token: string;
  teamName: string;
  teamNameError: boolean;
  isRenameModalOpen: boolean;
  isDeleteModalOpen: boolean;
  selectedRoles: string[];
  selectedTeam: string[];
  activated: string[];
  isRoleOpen: boolean;
  isTeamOpen: boolean;
  isApproveOpen: boolean;
  isCreateTeamModalOpen:boolean;
  messageToggle:boolean,
  tostifymessage:string,
  status:string,
  blankError:boolean,
  teamNameRenameError:boolean,
  teamRenameBlankError:boolean
}

interface SS {
  id: any;
}

export default class TeamBuilderController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTeamsListCallId = "";
  deleteTeamCallId = "";
  editTeamNameCallId = "";
  getMembersListCallId = "";
  postCreateTeamCallId:string = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      rows: [],
      dialogOpen: false,
      selectedRow: null,
      anchorEl: null,
      loading: false,
      token: "",
      teamName: "",
      teamNameError: false,
      isRenameModalOpen: false,
      isDeleteModalOpen: false,
      toggle: true,
      checked: false,
      membersList: [],
      selectedRoles: [],
      selectedTeam: [],
      activated: [],
      isRoleOpen: false,
      isTeamOpen: false,
      isApproveOpen: false,
      isCreateTeamModalOpen:false,
      messageToggle:false,
      tostifymessage:"",
      status:"",
      blankError:false,
      teamNameRenameError:false,
      teamRenameBlankError:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getTeamsListApi();
  }

  componentDidUpdate(prevProps: Props, prevState: S) {
    if (!this.state.toggle && prevState.toggle) {
      this.getMembersListApi();
    }
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  
      if (responseJson.errors) {
        this.handleApiError(apiCallId, responseJson);
        return;
      }
  
      switch (apiCallId) {
        case this.getTeamsListCallId:
          this.handleTeamsListResponse(responseJson);
          break;
        case this.editTeamNameCallId:
          this.handleEditTeamResponse(responseJson);
          break;
        case this.deleteTeamCallId:
          this.handleDeleteTeamResponse();
          break;
        case this.getMembersListCallId:
          this.handleMembersListResponse(responseJson);
          break;
        case this.postCreateTeamCallId:
          this.handleCreateTeamResponse(responseJson);
          break;
        default:
          break;
      }
    }
  }
  
  private handleApiError(apiCallId: string, responseJson: any) {
    if (apiCallId === this.editTeamNameCallId) {
      this.handleError(responseJson);
    } else if (apiCallId === this.postCreateTeamCallId) {
      this.handleCreateTeamResponse(responseJson);
    }else if(apiCallId === this.deleteTeamCallId){
      this.handleDeleteResponse(responseJson)
    }
  }
  handleDeleteResponse=(responseJson:any)=>{
      if(responseJson.errors){
        toast.success(
          `${responseJson.errors}`,
          {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            closeButton: false,
            theme: "light",
          }
        );
      }
  }
  private handleTeamsListResponse(responseJson: any) {
    const formatedData = responseJson.data.map((team: any) => ({
      id: team.id,
      name: team.attributes.team_name,
      members: team.attributes.members_count,
      dataTime: moment(team.attributes.created_at).format("DD MMMM YYYY"),
    }));
  
    this.setState({
      rows: formatedData,
    });
  }
  
  private handleEditTeamResponse(responseJson: any) {
    if (responseJson.data) {
      this.handleRenameModal();
      this.handleClose();
      this.setState({
        messageToggle: true,
        tostifymessage: "The team has been successfully renamed.",
        status: "success",
      });
      this.getTeamsListApi();
  
      setTimeout(() => {
        this.setState({ messageToggle: false, tostifymessage: "", status: "" });
      }, 2000);
    }
  }
  
  private handleDeleteTeamResponse() {
    this.handleDeleteModal();
    toast.success(
      `${this.state.selectedRow?.name} has been successfully deleted.`,
      {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        closeButton: false,
        theme: "light",
      }
    );
    this.handleClose();
    this.getTeamsListApi();
  }
  
  private handleMembersListResponse(responseJson: any) {
    const formatedData = responseJson.members.map((member: any, i: number) => ({
      id: `${i}`,
      ...member,
    }));
  
    this.setState({
      membersList: formatedData,
    });
  }

  handleError = (responseJson:any) =>{
    if(responseJson.errors == "Team name can't be blank"){
      this.setState({teamRenameBlankError : true  , teamNameRenameError :false });
      }else if(responseJson.errors == "Team name already exists"){
        this.setState({teamNameRenameError : true  , teamRenameBlankError:false});
      }
  }

  handleCreateTeamModal = () =>
  this.setState((prev) => ({
    isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
    teamName:"",
    teamNameError:false,
    blankError:false
  }));

  handleCreateTeamName = (teamName: string) => {
    this.setState({ teamName, teamNameError: false });
  };


  handleCreateTeamCancelButton = () =>{
    this.setState((prev) => ({
      isCreateTeamModalOpen: !prev.isCreateTeamModalOpen,
      teamName: "",
      teamNameError: false,
      blankError:false
    }));
  }

  handleCreateTeamSubmit = async() =>{
    const token = await getStorageData("token");

    const header = {
      "Content-Type": configJSON.ContentType,
      token: token,
    };
   
    const body = {
      data:{
        attributes:{
          "team_name":this.state.teamName
        }
      }  
    }
   
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.postCreateTeamCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.CreateTeamEndPoint}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

   handleCreateTeamResponse = async(responseJson:any) =>{
    await setStorageData("successData","true")
      if(!responseJson.errors){
        this.props.navigation.navigate("AddNewMember",{title:this.state.teamName , id:responseJson.data.id } ,{title:this.state.teamName ,id:responseJson.data.id })
         this.setState({
          teamName:"",
          isCreateTeamModalOpen:false,
         })
      
      }else{
           if(responseJson.errors == 'Team name already exists') {
            this.setState({ teamNameError:true , teamNameRenameError:false ,blankError:false, teamRenameBlankError:false})
           }else if (responseJson.errors == "Team name can't be blank"){
              this.setState({
                blankError:true
              })
           }
      }
    }

  handleRenameModal = () => {
    this.setState((prev) => ({
      isRenameModalOpen: !prev.isRenameModalOpen,
      teamName: this.state.selectedRow!.name,
      anchorEl: null,
      teamNameError: false,
      teamRenameBlankError:false,
      teamNameRenameError:false,
      blankError:false,
    }));
  };

  handleDeleteModal = () => {
    this.setState((prev) => ({
      isDeleteModalOpen: !prev.isDeleteModalOpen,
      anchorEl:null
    }));
  };

  handleTeamNameChange = (teamName: string) => {
    this.setState({ teamName, teamNameError: false  , blankError:false});
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msgSuccess = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msgSuccess.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msgSuccess);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };

  getTeamsListApi = (search = "") => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getTeamsListCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}?team_name=${search}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  deleteTeamApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.deleteTeamCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.delete
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  editTeamNameApi = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamName.trim(),
        },
      },
    };

    this.editTeamNameCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.selectedRow!.id}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };



  getMembersListApi = (search = "") => {
 
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListCallId = requestMsg.messageId;

    const roleFilterQuery = this.state.selectedRoles
      .map((role) => `&role_id[]=${role.toLowerCase()}`)
      .join("");

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllTeamMembersEndPoint}?user_name=${search}${roleFilterQuery}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);
  };

  handleToggle = () => {
    this.setState((prevState) => ({
      toggle: !prevState.toggle,
    }));
  };

  handleChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    const { name, value } = event.target;
    const isClear = (value as string[]).includes("clear");
    if (name === 'role'){
      this.setState(
        {
          selectedRoles: isClear ? [] : value as string[],
        },
        this.getMembersListApi
      );
    } else if(name == 'team'){
      this.setState({
        selectedTeam: value as string[],
      });
    } else if(name == 'activated'){
      this.setState({
        activated: value as string[],
      });
    }
  };

  getRoleStyles(role: string) {
    let backgroundColor;
    switch (role) {
      case 'owner':
        backgroundColor = '#237182';
        break;
      case 'employee':
        backgroundColor = '#C5E0E7';
        break;
      default:
        backgroundColor = '#E0EDF0';
    }
    const color = role === 'manager' ? '#237182' : '#fff';
    return { backgroundColor, color };
  }
}

// Customizable Area End
