import React from "react";

export const PdfIcon = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_19406_93290"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <rect width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_19406_93290)">
      <path
        d="M8.03304 14.8674C8.03304 14.5352 7.80257 14.3372 7.39592 14.3372C7.22987 14.3372 7.11741 14.3535 7.05859 14.3692V15.4349C7.12824 15.4506 7.21386 15.4558 7.33188 15.4558C7.76532 15.4558 8.03304 15.2368 8.03304 14.8674ZM10.5504 14.3483C10.3683 14.3483 10.2506 14.364 10.181 14.3803V16.7405C10.2506 16.7568 10.3631 16.7568 10.4647 16.7568C11.2035 16.7621 11.6853 16.3554 11.6853 15.4938C11.6905 14.7445 11.2515 14.3483 10.5504 14.3483Z"
        fill={props.color}
      />
      <path
        d="M15.2271 2H7.19431C6.02079 2 5.06567 2.95578 5.06567 4.12864V12H4.85776C4.38412 12 4 12.3837 4 12.8578V18.0598C4 18.5338 4.38408 18.9175 4.85776 18.9175H5.06567V19.8714C5.06567 21.0455 6.02079 22 7.19431 22H18.1548C19.3275 22 20.2828 21.0455 20.2828 19.8714V7.03792L15.2271 2ZM6.25026 13.7966C6.50164 13.7541 6.85499 13.7221 7.35281 13.7221C7.8559 13.7221 8.21447 13.8181 8.4554 14.011C8.68554 14.1927 8.84085 14.4928 8.84085 14.8459C8.84085 15.1989 8.72316 15.499 8.50903 15.7023C8.23052 15.9645 7.81865 16.0822 7.33684 16.0822C7.22961 16.0822 7.13349 16.0769 7.05833 16.0664V17.3564H6.25026V13.7966ZM18.1548 20.6964H7.19431C6.73992 20.6964 6.3699 20.3264 6.3699 19.8714V18.9175H16.5873C17.061 18.9175 17.4451 18.5338 17.4451 18.0598V12.8578C17.4451 12.3837 17.061 12 16.5873 12H6.3699V4.12864C6.3699 3.67491 6.73996 3.30489 7.19431 3.30489L14.7394 3.29701L14.7394 6.44445C14.7394 7.28844 14.9191 7.55557 15.4746 7.55557L18.9477 7.55433L18.9785 19.8713C18.9785 20.3264 18.6091 20.6964 18.1548 20.6964ZM9.3619 17.34V13.7966C9.6616 13.7489 10.0522 13.7221 10.4645 13.7221C11.1496 13.7221 11.5939 13.845 11.942 14.1071C12.3166 14.3856 12.5519 14.8295 12.5519 15.4669C12.5519 16.1573 12.3006 16.6339 11.9524 16.9281C11.5725 17.2438 10.9943 17.3936 10.2879 17.3936C9.86491 17.3936 9.5652 17.3668 9.3619 17.34ZM15.2037 15.2421V15.9056H13.9082V17.3564H13.0893V13.7489H15.2945V14.4176H13.9082V15.2421H15.2037Z"
        fill={props.color}
      />
    </g>
  </svg>
);
