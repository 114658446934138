import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { ChangeEvent } from "react";
import { State } from "country-state-city";
export interface IAttribute {
  name: string;
  title: string;
  field_type: string;
  is_enable: boolean;
  is_required: boolean;
  value: string | number;
}
interface ImageData {
  file_name: string;
  content_type: string;
  image: string;
  size: number;
}

const initialImageData: ImageData = {
  file_name: '',
  content_type: '',
  image: '',
  size: 0
};
export interface IAttributeValues {
  name: string;
  value: string;
}

type FieldType =
  | "string"
  | "file"
  | "textarea"
  | "text"
  | "date"
  | "datetime"
  | "integer"
  | "number"
  | "boolean"
  | "float"
  | "checkbox";
type FieldValue = string | number | boolean |File | Date | null;
export interface IField {
  name: string;
  title: string;
  field_type: FieldType;
  is_enable: boolean;
  is_required: boolean;
  placeholder:string;
}

interface IProfileDataWeb {
  attributes?: { user_profile_data: { [key: string]: FieldValue } };
}

export interface IProfileData {
  [key: string]: FieldValue;
}

export interface IProfileValues {
  value: string;
}
const numberRegex = /^\d*$/;
const textRegex = /^[a-zA-Z\s]*$/;

const formFields = [
  "first_name",
  "lastName",
  "countryCode",
  "phoneNumber",
  "companyName",
  "company_number",
  "vat",
  "street_name",
  "city",
  "zip_code",
  "state",
  "country",
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  companyNumberError:boolean;
  companyNumberErrorMsg:string;
  vatError:boolean;
 vatErrorMsg:string;
  streetNameError: boolean;
  streetNameErrorMsg: string;
  cityError:boolean;
cityErrorMsg:string;
  zipcodeError: boolean,
  zipcodeErrorMsg: string,
  stateError: boolean,
  stateErrorMsg: string,
  countryError: boolean,
  countryErrorMsg: string,
  first_name:string;
  firstNameError:boolean;
  firstNameErrorMsg:string;
  lastName:string;
  lastNameError:boolean;
  lastNameErrorMsg:string;
  countryCode:string;
  countryCodeError: boolean;
  countryCodeErrorMsg: string;
  phoneNumber:string;
  phoneNumberError:boolean;
  phoneNumberErrorMsg:string;
  companyName:string;
  companyNameError: boolean,
  companyNameErrorMsg: string,
  companyLogo: any;
  httpBodyPersonal: any;
  companyLogoError: boolean,
  companyLogoErrorMsg: string,
  inputKey: number,
  company_number:string;
  formComplete: boolean;
  vat:string;
  street_name:string;
  city:string;
  zip_code:string;
  state:string;
  country:string;
  validations: { validateFirstName: boolean; validateLastName: boolean; validatePhoneNumber: boolean; validateCompanyName: boolean; validateCompanyLogo: boolean; validateCountryCode: boolean
    validateCompanyNumber: boolean,
    street_nameMatch: boolean,
    validateVAT: boolean,
    cityMatch: boolean,
    zipcodeMatch: boolean,
    stateMatch: boolean,
    countryMatch: boolean,

  };
  imageData: {
    file_name: string;
    content_type: string;
    image: string;
    size:number;
  };
  editImageData:{
    file_name: string;
    content_type: string;
    image?: string;
    profile_picture?:string;
    size:number | string;
  };
  errors:{
    first_name:string;
    lastName:string;
    countryCode:string;
    phoneNumber:string;
    companyName:string;
    // companyLogo: File | null;
    company_number:string;
    vat:string;
    street_name:string;
    city:string;
    zip_code:string;
    state:string;
    country:string;
    firstNameError: string;
    lastNameError: string;
    phoneNumberError: string;
    companyNameError: string;
    companyLogoError: string;
    countryCodeError: string
    companyNumberError:string,
    vatError:string,

  }
  profile: { [key: string]: FieldValue };
  fields: IField[];
  currentProfile?: { [key: string]: FieldValue };
  profileImageUrl: string;
  saveEnabled?: boolean;
  cancelEnabled?: boolean;

  txtInputValue: string;
  txtSavedValue: string;

  fullName: string;
  email: string;
  address: string;
  attributesValues: IProfileValues[];
  countryOpen: boolean;
  postalCode: string;
  profilePicture: string;
  profileRole: string;
  aboutMe: string;
  gender: string;
  user_name: string;
  dateOfBirth: string;
  currentPassword: string;
  newPassword: string;
  reNewPassword: string;
  instagram: string;
  facebook: string;
  youtube: string;
  qrCode: string;
  profileId: string;
  user_type: string;
  imageModalVisible: boolean;
  userAge: number;
  biography: string;
  video: string;
  attributes: IAttribute[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;
  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;
  isDatePickerVisible: boolean;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;
  enableField: boolean;
  selectedCountry:string,
  selectedState:string,
  selectedCity:string
  editPersonalInformation: {
    first_name: string,
    last_name: string,
    phone_number: string,
    role_id: string,
    job_title: string,
    about_me: string,
    country_code: string,
    company_logo: string | File,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
  };
  editDetailToggle: boolean,
  editError: {
    first_name: string,
    last_name: string,
    phone_number: string,
    role_id: string,
    job_title: string,
    about_me: string,
    country_code: string,
    company_name: string,
    company_number: string,
    vat: string,
    street_name: string,
    city: string,
    zip_code: string,
    state: string,
    country: string
  },
  editPicToggle:boolean,
  previewImage:string,
  previewProfileImg:string,
  messageToggle:boolean,
  tostifymessage:string,
  status:string
  toggleEditPersonalInfoAndBusinessInfo:boolean,
  popUpToggle:boolean,
  dialogOpen: boolean,
  piToggleDropDown:boolean,
  piToggleRoleDropDown:boolean,
  deleteAccountToggle:boolean;
  deleteAccountPassword:string;
  currentAccountPassword: string;
  asteriskAccountCurrent:string;
  deleteAccountType:string;
  AccountTypError:boolean;
  showAccountCurrentPassword:boolean;
  currentPasswordError:string;
  CommonDeletionAccountError:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CustomisableUserProfilesController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCustomizableProfileCallId: string = "";
  getCustomizableProfileFieldsCallId: string = "";
  postCreateMemberCallId:string= "";
  updateCustomizableProfileCallId: string = "";
  getProfileAccountID: string = "";
  updateProfileID: string = "";
  getAttributesID: string = "";
  token: string = "";
  intervalId: number = 0;
  getCustomizableBusinessProfileCallId:string="";
  labelEmail: string = "";

  btnTextCancelPasswordChange: string = "";
  btnTextSaveChanges: string = "";
  labelHeader: string = "";
  btnTextChangePassword: string = "";
  createPersonalProfilesApiCallID: any;
  apiCallMessageUpdateProfileRequestId: string = "";
  validationApiCallId: string = "";
  apiChangePhoneValidation: string = "";
  registrationAndLoginType: string = "";
  authToken: string = "";
  uniqueSessionRequesterId: string = "";
  userProfileGetApiCallId: string = "";
  getPersonalInformation:string="";
  editPersonalInformationId:string = "";
  currentPasswordCheckId:string =  "";
  deleteAccountId:string = "";
  delayInputTimeoutId: NodeJS.Timeout | null = null;
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedCountry:'',
      selectedState:'',
      selectedCity:'',
      vatError:false,
      vatErrorMsg:'',
       companyNumberError:false,
       companyNumberErrorMsg:'',

      streetNameError: false,
      streetNameErrorMsg: '',
       cityError:false,
     cityErrorMsg:'',
      zipcodeError: false,
      zipcodeErrorMsg: '',
      stateError: false,
      stateErrorMsg: '',
     countryError:false,
      countryErrorMsg: '',
      first_name:"",
      firstNameError:false,
      firstNameErrorMsg:"",
      lastName:"",
      lastNameError:false,
      lastNameErrorMsg:"",
      countryCode:"+44",
      countryCodeError: false,
      countryCodeErrorMsg: '',
      phoneNumber: "",
      phoneNumberError:false,
      phoneNumberErrorMsg:'',
      companyName:"",
      companyNameError: false,
      companyNameErrorMsg: '',
      companyLogo: "",
      httpBodyPersonal: {},
      inputKey: Date.now(),
      companyLogoError: false,
      companyLogoErrorMsg: '',
      company_number:"",
      formComplete: false,
      validations:
        { validateFirstName: false, 
        validateLastName: false,
        validatePhoneNumber: false,
        validateCompanyName: false,
        validateCompanyLogo: false,
          validateCountryCode: false ,
        validateCompanyNumber: false,
        street_nameMatch: false,
        validateVAT: false,
        cityMatch: false,
        zipcodeMatch: false,
        stateMatch: false,
        countryMatch: false,
      },
      imageData: {
        file_name: '',
        content_type: '',
        image: '',
        size: 0
      },
      editImageData:{
        file_name: "",
        content_type: "",
        size:""
      },
      vat:"",
      street_name:"",
      city:"",
      zip_code:"",
      state:"",
      country:"",
      errors:{
        first_name:"",
        lastName:"",
        countryCode:"",
        phoneNumber: "",
        companyName:"",
        // companyLogo:null,
        company_number:"",
        vat:"",
        street_name:"",
        city:"",
        zip_code:"",
        state:"",
        country:"",
        firstNameError: "",
        lastNameError: "",
        phoneNumberError: '',
        companyNameError: '',
        companyLogoError: '',
        countryCodeError: '',
        companyNumberError:"",
        vatError:"",


      },
      profile: {},
      profileImageUrl: "",
      fields: [],

      attributes: [],
      attributesValues: [],
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      fullName: "",
      email: "",
      address: "",
      postalCode: "",
      profilePicture: "",
      profileRole: "jobseeker",
      aboutMe: "",
      gender: "",
      user_name: "",
      dateOfBirth: "",
      biography: "",
      currentPassword: "",
      newPassword: "",
      reNewPassword: "",
      youtube: "",
      instagram: "",
      facebook: "",
      qrCode: "",
      profileId: "",
      countryOpen: false,
      user_type: "",
      imageModalVisible: false,
      userAge: 0,
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      video: "",
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,
      isDatePickerVisible: false,

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      editPersonalInformation: {
        first_name: "",
        last_name: "",
        phone_number: "",
        role_id: "",
        job_title: "",
        about_me: "",
        country_code: "",
        company_logo: "",
        company_name: "",
        company_number: "",
        vat: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: ""
      },
      editDetailToggle: true,
      editError: {
        first_name: "",
        last_name: "",
        phone_number: "",
        role_id: "",
        job_title: "",
        about_me: "",
        country_code: "",
        company_name: "",
        company_number: "",
        vat: "",
        street_name: "",
        city: "",
        zip_code: "",
        state: "",
        country: ""
      },
      editPicToggle:true,
      previewImage:"",
      previewProfileImg:"",
      messageToggle:false,
      tostifymessage:"",
      status:"",
      toggleEditPersonalInfoAndBusinessInfo:true,
      popUpToggle:false,
      dialogOpen: false,
      piToggleDropDown:false,
      piToggleRoleDropDown:false,
      deleteAccountToggle:false,
      deleteAccountPassword:"",
      currentAccountPassword:"",
      asteriskAccountCurrent:"",
      deleteAccountType:"",
      AccountTypError:false,
      showAccountCurrentPassword:true,
      currentPasswordError:"",
      CommonDeletionAccountError:""
      // Customizable Area End
    };
    // Customizable Area Start
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);
    // this.checkToken = this.checkToken.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const pageToggle = localStorage.getItem("profilePageToggle");
    if(!pageToggle)
    this.getPersonalInformationAPI()
    this.setInitialValues();
    this.showMessageDuringUpdateEmailAndPassword()
    this.checkPageOpen()
    // this.checkToken();
    // Customizable Area End
  }

  getToken = () => {
    const message: Message = new Message(getName(MessageEnum.SessionRequestMessage));
    this.send(message);
  };

  onCustomizableProfileData = (data: IProfileDataWeb | null) => {
    if (data === null) return this.setState({ currentProfile: { ...this.state.profile } });
    if (!data) return;
    if (!data.attributes || !data.attributes.user_profile_data)
      return this.setState({ currentProfile: { ...this.state.profile } });

    const newProfile = data.attributes.user_profile_data;
    this.setState({ profile: newProfile, currentProfile: { ...newProfile } });
  };

  checkSession = (message: Message) => {
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      if (
        !message.getData(getName(MessageEnum.SessionResponseToken)) ||
        message.getData(getName(MessageEnum.SessionResponseToken)) === "null"
      )
        this.token =
          "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6NDY3LCJleHAiOjE2NzkwNDI3NTAsInRva2VuX3R5cGUiOiJsb2dpbiJ9.zi-zzVPBT-4TQm8cQK8uK6qsoDqTIL_rT-LZX1sptxseOUYhWeHtibNbbmdRUJTSz2THXUUuNlBfkIH6QCFGgg";
      else this.token = message.getData(getName(MessageEnum.SessionResponseToken));

      const testToken = window.localStorage.getItem("testToken");
      if (testToken) this.token = testToken;
      runEngine.debugLog("TOKEN1", this.token);
      this.getCustomizableProfile();
      this.getCustomizableProfileFields();
      return true;
    }
  };

  checkProfileAccount = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if (apiRequestCallId === this.getProfileAccountID) {
      if (!responseJson.data || !responseJson.data.attributes) return;
      const dataResponse = responseJson;
      const userProfile = dataResponse.data.attributes.user_profile_data || {};
      const about_me =
        dataResponse.data.attributes.profile_bio &&
        dataResponse.data.attributes.profile_bio.about_me;
      const qr_code =
        dataResponse.data.attributes.qr_code && dataResponse.data.attributes.qr_code.qr_code;
      this.setState({
        address: dataResponse.data.attributes.address,
        user_name: userProfile.user_name,
        aboutMe: about_me,
        qrCode: qr_code,
        instagram: dataResponse.data.attributes.instagram,
        city: dataResponse.data.attributes.city,
        postalCode: dataResponse.data.attributes.postal_code,
        fullName: dataResponse.data.attributes.name,
        first_name: dataResponse.data.attributes.first_name,
        lastName: dataResponse.data.attributes.last_name,
        profilePicture: userProfile.profile_pic,
        user_type: userProfile.user_type,
        dateOfBirth: dataResponse.data.attributes.dob,
        video: dataResponse.data.attributes.video,
        youtube: dataResponse.data.attributes.youtube,
        facebook: dataResponse.data.attributes.facebook,
        biography: dataResponse.data.attributes.bio,
        attributesValues: Object.keys(dataResponse.data.attributes.user_profile_data).map(
          (item) => {
            return {
              ...this.state.attributes[(item as unknown) as number],
              value: dataResponse.data.attributes.user_profile_data[item]
            };
          }
        )
      });
      this.setState({
        attributes: this.state.attributesValues.map((item, index) => {
          return {
            ...this.state.attributes[index],
            value: item.value
          };
        })
      });
      return true;
    }
  };

  checkOtherResponses = (message: Message) => {
    const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (apiRequestCallId === this.getCustomizableProfileCallId) {
      this.onCustomizableProfileData(responseJson.data);
    } else if (apiRequestCallId === this.getCustomizableProfileFieldsCallId) {
      if (!responseJson.data) return;
      const fields = responseJson.data;
      this.setState({ fields });
    } else if (apiRequestCallId === this.updateCustomizableProfileCallId) {
      this.setState({
        currentProfile: { ...this.state.profile },
        saveEnabled: false,
        cancelEnabled: false
      });
    } else if (apiRequestCallId === this.updateProfileID) {
      this.fetchProfileData();
      alert("Profile Updated Successfully");
    } else if (apiRequestCallId === this.getAttributesID) {
      this.fetchProfileData();
      this.setState({
        attributes: responseJson.data.map((item: object) => {
          return {
            ...item,
            value: ""
          };
        })
      });
    }
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    // if (this.checkSession(message)) return;
    runEngine.debugLog("Message Received", message);
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId === this.getCustomizableBusinessProfileCallId) {
        if (responseJson.data && responseJson.data.id) {
          this.clearLocalStorage();
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMessage)
          );
          msg.addData(
            getName(MessageEnum.NavigationTargetMessage),
            'AddNewTeamMember'
          );
          msg.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
          );
          this.send(msg);
          return;
        }
      }

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createPersonalProfilesApiCallID){
          this.createPersonalProfilesApiCallMethod(responseJson, errorResponse);
        } else{
          console.log("Unhandled API call ID:", apiRequestCallId);
        }
      } else {
        console.log("Invalid API request or response");
      }

      if (apiRequestCallId === this.getPersonalInformation) {
        if (responseJson) {
          const {
            first_name,
            last_name,
            phone_number,
            role_id,
            job_title,
            about_me,
            country_code,
            company_logo,
            profile_picture,
            company_name,
            company_number,
            vat,
            street_name,
            city,
            zip_code,
            state,
            country,
            email
          } = responseJson.data.attributes;
          this.setState({
            editPersonalInformation: {
              first_name,
              last_name,
              phone_number,
              role_id,
              job_title: job_title === undefined || job_title === null ? "" : job_title,
              about_me: about_me === undefined || about_me === null ? "" : about_me,
              country_code,
              company_logo,
              company_name,
              company_number,
              vat,
              street_name,
              city,
              zip_code,
              state,
              country
            }
          });
          this.setState({ previewImage: company_logo,previewProfileImg:profile_picture });
          localStorage.setItem("email", email);
        }
      }

      if (apiRequestCallId === this.editPersonalInformationId) {
        this.cancelEditDetails();
        this.getPersonalInformationAPI();
        this.setState({ messageToggle: true, tostifymessage: this.state.toggleEditPersonalInfoAndBusinessInfo?"Personal Information Changed!":"Business Information Changed!", status: "success" }, () => {
          this.closeMessageBox();
        })
      }

      if(this.currentPasswordCheckId === apiRequestCallId){
        if (responseJson.errors) {
          this.setState({ currentPasswordError:responseJson.errors[0].password && "The password you entered is incorrect." });  
        } else {
          this.setState({ currentPasswordError:"" });
        }
      }

      if(this.deleteAccountId === apiRequestCallId){
        this.handleDeleteAccountResponse(responseJson);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleDeleteAccountResponse(responseJson:{ message:string }) {
    const successMessage = "Account deletion initiated. You have 30 days to reactivate your account. You have been logged out.";
    if (responseJson.message === successMessage) {
      this.clearLocalStorageAndNotifyAccountDeletion();
    } else {
      this.setState({ CommonDeletionAccountError: responseJson.message });
    }
  }

  clearLocalStorageAndNotifyAccountDeletion() {
    localStorage.clear();
    localStorage.setItem("message", JSON.stringify({
      messageToggle: true,
      tostifymessage: "Your account has been deleted. We're sorry to see you go.",
      status: "success"
    }));
    this.props.navigation.navigate("EmailAccountLoginBlock");
  }

  setInitialValues = () => {
    Object.keys(window.localStorage).forEach((key: string) => {
      if (key === "imageData") {
        this.setState((prev) => ({
          ...prev,
          [key]: JSON.parse(window.localStorage.getItem(key)!),
        }))
      } else {
        this.setState((prev) => ({
          ...prev,
          [key]: window.localStorage.getItem(key),
        }))
      }
    });
  };

  clearLocalStorage = () => {
    Object.keys(window.localStorage).forEach((key: string) => {
      if (formFields.includes(key)) {
        window.localStorage.removeItem(key);
      }
    });
  };

  popUpToggle = ()=> {
    this.setState({ popUpToggle:!this.state.popUpToggle });
  }

  handleChangePage = (val: boolean) => {
   
    if (!this.state.editDetailToggle) {
      this.popUpToggle();
    } else {
      this.setState({ editDetailToggle: true });
      this.setState({ editPicToggle: true });
      this.resetError();
      this.setState({ toggleEditPersonalInfoAndBusinessInfo: val });
      this.getPersonalInformationAPI();
    }
  }

  continueChanges = (val: boolean) => {
    this.setState({ editDetailToggle: true });
    this.setState({ editPicToggle: true });
    this.resetError();
    this.setState({ toggleEditPersonalInfoAndBusinessInfo: val });
    this.getPersonalInformationAPI();
    this.popUpToggle();
  }
  
  closeMessageBox = () => {
    setTimeout(()=>{
     this.setState({messageToggle:false, tostifymessage:"", status:""})
    },2000);
  }

  handleClickInsideReplaceRemovePic = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
  }

  handleClickOutsideReplaceRemovePic = ()=>{
    this.setState({ editPicToggle:true });
  }

  handleFileChange = (event: React.ChangeEvent<{ value: unknown }>,type:string) => {
   const input = event.target as HTMLInputElement;
   const file = input.files?.[0];
   if (file) {
     const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
     const maxFileNameLength = 50; // Set the maximum allowed file name length;

    if (!allowedTypes.includes(file.type)) {
      this.setState({ messageToggle:true, tostifymessage:'Invalid file format. Please upload an image (JPEG, PNG, JPG, SVG).', status:"failed" }, ()=>{
        this.closeMessageBox();
      })  
      return;
    } else if (file.name.length > maxFileNameLength) {
      this.setState({ messageToggle:true, tostifymessage:`File name is too long. Maximum length is ${maxFileNameLength} characters.`, status:"failed" }, ()=>{
        this.closeMessageBox();
      })
      return;
    } else if(file.size > 5000000){
      this.setState({ messageToggle:true, tostifymessage:`The file is too large. Please ensure it is 5 MB or less.`, status:"failed" }, ()=>{
        this.closeMessageBox();
      })
      return;
    }

     const url = URL.createObjectURL(file);
     const reader = new FileReader();
     if(type==="profile"){
       this.setState({previewProfileImg:url});
       this.replaceRemovePicToggle();
       reader.onloadend = () => {
         this.setState({
          editImageData: {
             file_name: file.name,
             content_type: file.type,
             profile_picture:reader.result as string,
             size: file.size
           },
         }); // Call validateForm or other logic after state update
       };
     }else{
      this.setState({ previewImage:url });
      this.replaceRemovePicToggle();
      reader.onloadend = () => {
        this.setState({
         editImageData: {
            file_name: file.name,
            content_type: file.type,
            image: reader.result as string,
            size: file.size
          },
        }); // Call validateForm or other logic after state update
      };
     }
     reader.readAsDataURL(file); // Read file as data URL for preview or processing
   }
  };

  removePhoto = (type:string) => {
    this.setState({ previewImage:"" });
    this.replaceRemovePicToggle();
    if(type==="profile"){
      this.setState({
        editImageData: {
           ...this.state.editImageData,profile_picture:""
         },
       }); 
    }else{
      this.setState({
        editImageData: {
          ...this.state.editImageData,
           file_name: "",
           content_type: "",
           image: "",
           size: ""
         },
       }); 
    }
  }
  
  desiableCheck = () => {
    return !Object.values(this.state.editError).every(i => i == "");
  }
  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    this.desiableCheck();
    if (prevState.currentAccountPassword !== this.state.currentAccountPassword) {
      if (this.delayInputTimeoutId) {
        clearTimeout(this.delayInputTimeoutId);
      }

      this.delayInputTimeoutId = setTimeout(() => {
      if(this.state.currentAccountPassword != "")
       this.checkCurrentPassword();
      }, 500);
    }
  }
  editValidation = (val: string, name: string) => {
    const error = { ...this.state.editError };
    const trimmedVal = val.trim();
  
    const setError = (field: keyof typeof error, message: string) => {
      error[field] = message;
    };
  
    if (trimmedVal === "") {
      setError(name as keyof typeof error, "This field is required");
    } else {
      const commonValidations: { [key: string]: { regex: RegExp, invalidMessage: string } } = {
        phone_number: { regex: numberRegex, invalidMessage: "Field contains invalid characters" },
        zip_code: { regex: /^[A-Za-z0-9]+$/, invalidMessage: "Field contains invalid characters" },
        company_number: { regex: /^[A-Za-z0-9\s]+$/, invalidMessage: "Field contains invalid characters" },
        vat: { regex: /^[a-zA-Z0-9]{2,15}$/, invalidMessage: "Invalid VAT number format" },
        street_name: { regex: /^[A-Za-z0-9\s]+$/, invalidMessage: "Field contains invalid characters" },
        company_name: { regex: /^[a-zA-Z!@#$%^&*(),.?":{}|<> ]+$/, invalidMessage: "Field contains invalid characters" }
      };
  
      const validation = commonValidations[name];
      if (validation) {
        if (!validation.regex.test(trimmedVal)) {
          setError(name as keyof typeof error, validation.invalidMessage);
        } else {
          setError(name as keyof typeof error, "");
        }
      } else if (name === "phone_number") {
        if (trimmedVal.length < 10) {
          setError("phone_number", "Phone number must be 10 - 15 digits");
        }
      } else if (name === "zip_code" && trimmedVal.length < 5) {
        setError("zip_code", "Zip code must contain 5 numbers");
      } else if (name === "state" || name === "country") {
        setError("state", "");
        setError("country", "");
      } else if (!textRegex.test(trimmedVal)) {
        setError(name as keyof typeof error, "Field contains invalid characters");
      } else {
        setError(name as keyof typeof error, "");
      }
    }
  
    this.setState({ editError: error });
  };
  
  

  handleChangeEdit = (val: string, name: string) => {
    this.editValidation(val, name);
    this.setState({ editPersonalInformation: { ...this.state.editPersonalInformation, [name]: val } });
    if (name == "country") {
      setTimeout(() => {
          let assiginData = State.getStatesOfCountry(val)[0].isoCode;
          this.handleChangeEdit(assiginData, "state");
      }, 500);
    }
  }
  
  replaceRemovePicToggle = () => {
   this.setState({ editPicToggle:!this.state.editPicToggle });
  }

  EditCountryCode = (event: React.ChangeEvent<{ value: unknown }>) => {
    const val = event.target.value as string;
    this.setState({ editPersonalInformation: { ...this.state.editPersonalInformation, country_code:val.slice(1)  } });
  };

  editDetailsToggle = () => {
    this.setState({ editDetailToggle: !this.state.editDetailToggle });
    this.setState({ editPicToggle:true });
  }
  cancelEditDetails = () => {
    this.editDetailsToggle();
    this.getPersonalInformationAPI();
    this.resetError();
  }
  resetError = () => {
    const error = { ...this.state.editError };
    const resetObj = Object.keys(error).reduce((acc, key) => {
      acc[key as keyof typeof error] = "";
      return acc;
    }, {} as typeof error);
    this.setState({ editError: resetObj });
  }
  checkToken = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      // Redirect to EmailAccountLoginBlock if token is not found
      const msg: Message = new Message(
        getName(MessageEnum.NavigationMessage)
      );
      msg.addData(
        getName(MessageEnum.NavigationTargetMessage),
        'EmailAccountLoginBlock'
      );
      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );
      this.send(msg);
      return;

    }

  };
  createPersonalProfiles = () => {
    this.setState(
      {
        firstNameError: this.isStringNullOrBlank(this.state.first_name) && this.state.firstNameError,
        lastNameError: this.isStringNullOrBlank(this.state.lastName) && this.state.lastNameError,
        phoneNumberError: this.isStringNullOrBlank(this.state.phoneNumber) && this.state.phoneNumberError,
        companyNameError: this.isStringNullOrBlank(this.state.companyName) && this.state.companyNameError,
        countryCodeError: this.isStringNullOrBlank(this.state.countryCode) && this.state.countryCodeError,
        companyLogoError: this.isStringNullOrBlank(this.state.companyLogo) && this.state.companyLogoError,
      },
      () => {
        if (
          this.isStringNullOrBlank(this.state.first_name) ||
          this.isStringNullOrBlank(this.state.lastName) ||
          this.isStringNullOrBlank(this.state.countryCode) ||
          this.isStringNullOrBlank(this.state.phoneNumber) ||
          this.isStringNullOrBlank(this.state.companyName) ||
          this.isStringNullOrBlank(this.state.companyLogo)
        ) {
         
          return false; // Stops execution if any required fields are missing
        }
        const token = localStorage.getItem('token');
        const id = localStorage.getItem('id');
        const header = {
          "Content-Type": "application/json",
          "token": token
        };
        const attrs = {
          first_name: this.state.first_name,
          last_name: this.state.lastName,
          country_code: this.state.countryCode,
          phone_number: this.state.phoneNumber,
          company_name: this.state.companyName
        };
        const oneAttrs = {
          file_name: this.state.imageData.file_name,
          content_type: this.state.imageData.content_type,
          image: this.state.imageData.image
        };
        const data = {
          attributes: attrs,
          user_avatar: oneAttrs
        };
        const msg = new Message(
          getName(MessageEnum.NavigationMessage)
        );
        msg.addData(
          getName(MessageEnum.NavigationTargetMessage),
          'CustomisableBusinessProfiles'
        );
        msg.addData(
          getName(MessageEnum.NavigationPropsMessage),
          { ...this.props, data }
        );
        this.send(msg);
      });
  };

  handleCountryChange = (event:any) => {
    const countryIsoCode = event.target.value;
    this.setState({ 
      country: countryIsoCode,
      state: '',
      countryError: false
    });
    window.localStorage.setItem("country", countryIsoCode);
    this.validateForms()
  };

  handleStateChange = (event:any) => {
    const stateIsoCode = event.target.value;
    this.setState({ 
      state: stateIsoCode,
      stateError: false
    });
    window.localStorage.setItem("state", stateIsoCode);
    this.validateForms()
  };

  handleCityChange = (event:any) => {
    this.setState({ city: event.target.value });
    this.validateForms()
  };



  isStringNullOrBlank(str: string | null | File): boolean {
    if (str === null) return true;
    if (typeof str === 'string') return str.length === 0;
    return false; // assuming that a File object is not considered null or blank
  }

createPersonalProfilesApiCallMethod=(responseJsonData:any,errorResponseData:any)=>{ 

    if (responseJsonData !== undefined && responseJsonData.data) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

    } else {
      //Check Error Response
      // this.parseApiErrorResponse(responseJsonData);
    }
    this.parseApiCatchErrorResponse(errorResponseData);
  }

  handleChangeAttributeValue = (value: string, index: number) => {
    const temporaryValue = this.state.attributes;
    temporaryValue[index].value = value;
    this.setState({ attributes: temporaryValue });
  };

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  checkRequiredFields = () => {
    if (!this.state.saveEnabled) {
      for (const field of this.state.fields) {
        if (field.is_required && !this.state.profile[field.name]) return;
      }
      this.setState({ saveEnabled: true });
    } else {
      for (const field of this.state.fields) {
        if (field.is_required && this.state.profile[field.name] === undefined)
          return this.setState({ saveEnabled: false });
      }
    }
  };


  changeFormValue = (
    valueKey: string,
    value: string | Date | number | boolean | File | null,
    fieldType: FieldType
  ) => {
    if (value !== undefined) {
      if (fieldType === "float") value = parseFloat(value as string);
      else if (fieldType === "integer") value = parseInt(value as string);
      else if (fieldType === "date") value = (value as Date).toDateString();
      else if (fieldType === "boolean") value = value === "true";
      else if (fieldType === "checkbox") value = !!value;
    }
    this.setState({ profile: { ...this.state.profile, [valueKey]: value } });
  };

  onKeyPress = (event: React.KeyboardEvent, field: IField) => {

  };
  getCustomizableProfileFields = () => {

  };
  getCustomizableProfile = () => {

  };
  checkForRequiredFields = () => {
    const RequiredField = this.state.attributes.map((item: IAttribute) => {
      if (item.is_required && !(item.value as string).trim() && item.is_enable) {
        alert(`Please Enter ${item.name}`);
        return false;
      }
      return true;
    });
    if (RequiredField?.includes(false)) {
      return false;
    } else {
      this.updateProfile();
    }
  };

  cancelChanges = () => {
    if (this.state.currentProfile)
      this.setState({
        profile: { ...this.state.currentProfile },
        saveEnabled: false,
        cancelEnabled: false
      });
  };

  updateCustomizableProfile = () => {
    const header = {
      "Content-Type": configJSON.searchApiContentType,
      token: this.token
    };

    const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomizableProfileCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.updateCustomizableProfileEndPoint}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(this.state.profile)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  fetchProfileData = async () => {

  };

  fetchAttributes = async () => {

  };

  updateProfile = async () => {

  };


  updateCompanyName = (event: { target: { value: any; }; }) => {
    const { value } = event.target;
    this.setState({ companyName: value }, this.validateCompanyName);
  };

  validateCompanyName = () => {
    const { companyName } = this.state;
    let companyNameError = false;
    let companyNameErrorMsg = '';
    let validations = { ...this.state.validations, validateCompanyName: true };
    const allowedPattern = /^[a-zA-Z0-9\s!@#$%^&*()_+\-={}\[\]|;:'",.<>?/`~\\]+$/;
  
    if (!companyName) {
      companyNameError = true;
      companyNameErrorMsg = 'Enter the company name';
      validations.validateCompanyName = false;
    } 
    else if (!allowedPattern.test(companyName)) {
      companyNameError = true;
      companyNameErrorMsg = 'Company name contains invalid characters';
      validations.validateCompanyName = false;
    }  else {
      window.localStorage.setItem("companyName", this.state.companyName);
    }
  
    this.setState({ companyNameError, companyNameErrorMsg, validations }, this.validateForm);
  };

  updateCountryCode = (event: { target: { value: any; }; }) => {
    const newCountryCodeSelected = event.target.value;
    this.setState({  phoneNumber: '',countryCode: newCountryCodeSelected}, () => this.validatePhoneNumber());
  };
  
  
  updatePhoneNumber = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const { countryCode } = this.state;
    const validCountryCodes = [
      { code: 'ES', dial_code: '+34', phone_length: 9},
      { code: 'PRT', dial_code: '+351', phone_length: 9},
      { code: 'RU', dial_code: '+7', phone_length: 10 },
      { code: 'GB', dial_code: '+44', phone_length: 10 },
      { code: 'CA', dial_code: '+123', phone_length: 7 },
      { code: 'DE', dial_code: '+49', phone_length: 10 },
      { code: 'CHN', dial_code: '+86', phone_length: 11 },
      { code: 'SA', dial_code: '+966', phone_length: 9 },
    ];
  
    const selectedCountry = validCountryCodes.find(country => country.dial_code === countryCode);
    const maxLength = selectedCountry ? selectedCountry.phone_length : 10;
  
    if (value.length <= maxLength) {
      this.setState({ phoneNumber: value }, this.validatePhoneNumber);
    } else {
      this.setState({ phoneNumber: value.slice(0, maxLength) }, this.validatePhoneNumber);
    }
  };
  
  validatePhoneNumber = () => {
    const { phoneNumber, countryCode } = this.state;
    let phoneNumberError = false;
    let phoneNumberErrorMsg = '';
    let validations = { ...this.state.validations, validatePhoneNumber: true };
    const validCountryCodes = [
      { code: 'GB', dial_code: '+44', phone_length: 10 },
      { code: 'CA', dial_code: '+123', phone_length: 7 },
      { code: 'RU', dial_code: '+7', phone_length: 10 },
      { code: 'CHN', dial_code: '+86', phone_length: 11 },
      { code: 'SA', dial_code: '+966', phone_length: 9 },
      { code: 'DE', dial_code: '+49', phone_length: 10 },
      { code: 'ES', dial_code: '+34', phone_length: 9 },
      { code: 'PRT', dial_code: '+351', phone_length: 9 }, ];
  
    const selectedCountry = validCountryCodes.find(country => country.dial_code === countryCode);
    const requiredLength = selectedCountry ? selectedCountry.phone_length : 10; 
  
    if (!phoneNumber) {
      phoneNumberError = true;
      phoneNumberErrorMsg = 'Enter the phone number';
      validations.validatePhoneNumber = false;
    } else if (phoneNumber.length !== requiredLength) {
      phoneNumberError = true;
      phoneNumberErrorMsg = `Phone number must be ${requiredLength} digits for the selected country`;
      validations.validatePhoneNumber = false;
    } else {
      window.localStorage.setItem("phoneNumber", phoneNumber);
    }
  
    this.setState({ phoneNumberError, phoneNumberErrorMsg, validations }, this.validateForm);
  };
  
  updateCompanyLogo = (event: any) => {
    const file = event.target.files ? event.target.files[0] : null;
    
    if (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
      const maxFileNameLength = 50; 
  
      if (!allowedTypes.includes(file.type)) {
        
        this.setState({
          companyLogoError: true,
          companyLogoErrorMsg: 'Invalid file format. Please upload an image (JPEG, PNG, JPG, SVG).',
          companyLogo: null,
          imageData: { file_name: '', content_type: '', image: '', size: 0 },
        });
        return;
      } else if (file.name.length > maxFileNameLength) {
     
        this.setState({
          companyLogoError: true,
          companyLogoErrorMsg: `File name is too long. Maximum length is ${maxFileNameLength} characters.`,
          companyLogo: null,
          imageData: { file_name: '', content_type: '', image: '', size: 0 },
        });
        return;
      }
  
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageData = {
          file_name: file.name,
          content_type: file.type,
          image: reader.result as string,
          size: file.size,
        };
  
       
        this.setState({
          companyLogo: URL.createObjectURL(file),
          imageData,
          companyLogoError: false,
          companyLogoErrorMsg: '',
        }, this.validateForm); 
  
      
        localStorage.setItem("companyLogo", URL.createObjectURL(file));
        localStorage.setItem("imageData", JSON.stringify(imageData));
      };
  
      reader.readAsDataURL(file);
    }
  };
  validateCompanyLogo = () => {
    const { companyLogo, imageData } = this.state;
    let companyLogoError = false;
    let companyLogoErrorMsg = '';
    const maxFileNameLength = 20;
    const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/svg+xml'];
  
    let validations = { ...this.state.validations, validateCompanyLogo: true };
  
    if (!companyLogo) {
      companyLogoError = true;
      companyLogoErrorMsg = 'Upload the company logo';
      validations.validateCompanyLogo = false;
    } else if (!allowedTypes.includes(imageData.content_type)) {
      companyLogoError = true;
      companyLogoErrorMsg = 'Invalid file format. Please upload an image (JPEG, PNG, JPG, SVG).';
      validations.validateCompanyLogo = false;
    } else if (imageData.file_name.length > maxFileNameLength) {
      companyLogoError = true;
      companyLogoErrorMsg = `File name is too long. Maximum length is ${maxFileNameLength} characters.`;
      validations.validateCompanyLogo = false;
    }
  
    this.setState({ companyLogoError, companyLogoErrorMsg, validations });
    this.validateForm();
  };
  
  
  
  updateLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ lastName: value }, this.validateLastName);
  };

  validateLastName = () => {
    const { lastName } = this.state;
    let lastNameError = false;
    let lastNameErrorMsg = '';
    let validations = { ...this.state.validations, validateLastName: true };

    if (!lastName) {
      lastNameError = true;
      lastNameErrorMsg = 'Enter the last name';
      validations.validateLastName = false;
    }
    else if (!/^[a-zA-Z\s]+$/.test(lastName)) {
      lastNameError = true;
      lastNameErrorMsg = 'Last name should contain only alphabets';
      validations.validateLastName = false;
    } else {
      window.localStorage.setItem("lastName", this.state.lastName);
    }

    this.setState({ lastNameError, lastNameErrorMsg, validations, errors: { ...this.state.errors, lastNameError: lastNameErrorMsg } }, this.validateForm);
  };

  updateFirstName = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ first_name: value }, this.validateFirstName);
  };

  validateFirstName = () => {
    const { first_name } = this.state;
    let firstNameError = false;
    let firstNameErrorMsg = '';
    let validations = { ...this.state.validations, validateFirstName: true };
  
    if (!first_name) {
      firstNameError = true;
      firstNameErrorMsg = 'Enter the first name';
      validations.validateFirstName = false;
    }
    else if (!/^[a-zA-Z\s]+$/.test(first_name)) {
      firstNameError = true;
      firstNameErrorMsg = 'First name should contain only alphabets';
      validations.validateFirstName = false;
    } else {
      window.localStorage.setItem("first_name", this.state.first_name);
    }

    this.setState({
      firstNameError,
      firstNameErrorMsg,
      validations,
      errors: { ...this.state.errors, firstNameError: firstNameErrorMsg }
    }, this.validateForm);
  };
  


  validateCountryCode = () => {
    const { countryCode } = this.state;
    let countryCodeError = false;
    let countryCodeErrorMsg = '';

    if (!countryCode) {
      countryCodeError = true;
      countryCodeErrorMsg = 'Select the country code';
    }

    window.localStorage.setItem("countryCode", this.state.countryCode)
    this.setState({ countryCodeError, countryCodeErrorMsg }, this.validateForm);
  };

  handleDrop = (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        this.setState({
          companyLogo: URL.createObjectURL(file),
          imageData: {
            file_name: file.name,
            content_type: file.type,
            image: reader.result as string,
            size: file.size
          }
        }, this.validateForm);
      };
      reader.readAsDataURL(file);
    }
  };

  renderFileSize(bytes: number): string {
    if (bytes < 1024) {
      return `${bytes} B`;
    } else if (bytes < 1024 * 1024) {
      const kb = bytes / 1024;
      return `${kb.toFixed(2)} KB`;
    } else {
      const mb = bytes / (1024 * 1024);
      return `${mb.toFixed(2)} MB`;
    }
  }

  handleDelete = () => {
    this.setState({ companyLogo: null, imageData: initialImageData }, this.validateForm);
    localStorage.removeItem("companyLogo");
    localStorage.removeItem("imageData");
  };

  getHelperText = (error: any, errorMsg: any) => {
    return error ? errorMsg : '';
  };

  validateForm = () => {
    const { first_name, lastName, countryCode, phoneNumber, companyName, companyLogo } = this.state;
    const formComplete = first_name !== '' && lastName !== '' && countryCode !== '' && phoneNumber !== '' && companyName !== '' && companyLogo !== null;
    this.setState({ formComplete });
  };
  validateForms = () => {
   
    const { company_number, vat, street_name, city, zip_code, state ,country} = this.state;
    const formComplete = Boolean(
      company_number.trim() !== '' &&
      vat.trim() !== '' &&
      street_name.trim() !== '' &&
      city.trim() !== '' &&
      zip_code.trim() !== '' &&
      state && state.trim() !== '' &&
      country && country.trim() !== ''
    );

    this.setState({ formComplete });
  };
  updateCompanyNumber= (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ company_number: value }, this.validateCompanyNumber);
  };

  validateCompanyNumber = () => {
    const { company_number } = this.state;
    let companyNumberError = false;
    let companyNumberErrorMsg = '';
    let validations = { ...this.state.validations, validateCompanyNumber: true };
  
    
    if (!company_number) {
      companyNumberError = true;
      companyNumberErrorMsg = 'Enter the number';
      validations.validateCompanyNumber = false;
    } 
    else if (!/^[a-zA-Z0-9\s]+$/.test(company_number)) {
      companyNumberError = true;
      companyNumberErrorMsg = 'Special characters are not allowed';
      validations.validateCompanyNumber = false;
    } else {
      window.localStorage.setItem("company_number", this.state.company_number)
    }
  
    this.setState({ 
      companyNumberError, 
      companyNumberErrorMsg, 
      validations, 
      errors: { ...this.state.errors, companyNumberError: companyNumberErrorMsg } 
    }, this.validateForms);
  };


  updateVAT = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ vat: value }, this.validateVAT);
  };

  validateVAT = () => {
    const { vat } = this.state;
    let vatError = false;
    let vatErrorMsg = '';
    let validations = { ...this.state.validations, validateVAT: true };

    if (!vat) {
      vatError = true;
      vatErrorMsg = 'Enter the VAT number';
      validations.validateVAT = false;
    } else if (!/^[a-zA-Z0-9\s]+$/.test(vat)) {
      vatError = true;
      vatErrorMsg = 'Special characters are not allowed';
      validations.validateVAT = false;
    } else {
      window.localStorage.setItem("vat", this.state.vat)
    }

    this.setState({
      vatError,
      vatErrorMsg,
      validations,
      errors: { ...this.state.errors, vatError: vatErrorMsg }
    }, this.validateForms);
  };
  
  updateCity= (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ city: value }, this.validateCity);
  };

  validateCity = () => {
    const { city } = this.state;
    let cityError = false;
    let cityErrorMsg = '';
    let validations = { ...this.state.validations, cityMatch: true };
  
    if (!city) {
      cityError = true;
      cityErrorMsg = 'Enter the city';
      validations.cityMatch = false;
    } else if (!/^[a-zA-Z\s]+$/.test(city) || !/[a-zA-Z]/.test(city)) {
      cityError = true;
      cityErrorMsg = 'City name must contain only letters ';
      validations.cityMatch = false;
    } else {
      window.localStorage.setItem("city", this.state.city)
    }
  
    this.setState({ 
      cityError, 
      cityErrorMsg, 
      validations, 
      errors: { ...this.state.errors, city: cityErrorMsg } 
    }, this.validateForms);
  };
  
  updateZipCode = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    const formattedValue = value.replace(/[^a-zA-Z0-9]/g, '').slice(0, 15);
  
    this.setState({
      zip_code: formattedValue
    },this.validateZipCode);
  };

  validateZipCode = () => {
    const { zip_code } = this.state;
    let zipcodeError = false;
    let zipcodeErrorMsg = '';
    let validations = { ...this.state.validations, zipcodeMatch: true };
  
    if (!zip_code) {
      zipcodeError = true;
      zipcodeErrorMsg = 'Enter the zipcode';
      validations.zipcodeMatch = false;
    } else if (!/^[a-zA-Z0-9]{1,15}$/.test(zip_code)) {
      zipcodeError = true;
      zipcodeErrorMsg = 'Zip code should be alphanumeric, and up to 15 characters long';
      validations.zipcodeMatch = false;
    }  else {
      window.localStorage.setItem("zip_code", this.state.zip_code)
    }
  
    this.setState({ 
      zipcodeError, 
      zipcodeErrorMsg, 
      validations, 
      errors: { ...this.state.errors, zip_code: zipcodeErrorMsg } 
    }, this.validateForms);
  };
  
  
  updateStreetName= (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    this.setState({ street_name: value }, this.validateStreetName);
  };

  validateStreetName=  () => {
    const { street_name } = this.state;
    let streetNameError = false;
    let streetNameErrorMsg = '';
    let validations = { ...this.state.validations, street_nameMatch: true };

    if (!street_name) {
      streetNameError = true;
      streetNameErrorMsg = 'Enter the street name';
      validations.street_nameMatch = false;
    } else if (!/^[a-zA-Z0-9\s]+$/.test(street_name)) {
      streetNameError = true;
      streetNameErrorMsg = 'Street name must not contain special character';
      validations.validateVAT = false;
    } else {
      window.localStorage.setItem("street_name", this.state.street_name)
    }

    this.setState({ streetNameError, streetNameErrorMsg, validations, errors: { ...this.state.errors, street_name: streetNameErrorMsg } }, this.validateForms);
  };
  updateState = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ state: event.target.value as string }, this.validateState);
    this.validateForms()
  };

  validateState= () => {
    const { state } = this.state;
    let stateError = false;
    let stateErrorMsg = '';

    if (!state) {
      stateError = true;
      stateErrorMsg = 'Select the state';
    }

    this.setState({ stateError, stateErrorMsg }, this.validateForm);
  };
  updateCountry= (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ country: event.target.value as string }, this.validateCountry);
    this.validateForms()
  };

  validateCountry= () => {
    const { country } = this.state;
    let countryError = false;
    let countryErrorMsg = '';

    if (!country) {
      countryError = true;
      countryErrorMsg = 'Select the country';
    }

    this.setState({ countryError, countryErrorMsg }, this.validateForms);
  };

  handleOpenDialog = () => {
    this.setState({ dialogOpen: true });
  };
  
  handleCloseDialog = () => {
    this.setState({ dialogOpen: false });
  };
  handleSubmit = () => {
    this.setState({
      companyNumberError: this.isStringNullOrBlank(this.state.company_number) && this.state.companyNumberError,
      vatError: this.isStringNullOrBlank(this.state.vat) && this.state.vatError,
      streetNameError: this.isStringNullOrBlank(this.state.street_name) && this.state.streetNameError,
      cityError: this.isStringNullOrBlank(this.state.city) && this.state.cityError,
      zipcodeError: this.isStringNullOrBlank(this.state.zip_code) && this.state.zipcodeError,
      stateError: this.isStringNullOrBlank(this.state.state) && this.state.stateError,
      countryError: this.isStringNullOrBlank(this.state.country) && this.state.countryError,
    });

    if (
      this.isStringNullOrBlank(this.state.company_number) ||
      this.isStringNullOrBlank(this.state.vat) ||
      this.isStringNullOrBlank(this.state.street_name) ||
      this.isStringNullOrBlank(this.state.city) ||
      this.isStringNullOrBlank(this.state.zip_code) ||
      this.isStringNullOrBlank(this.state.state) || this.isStringNullOrBlank(this.state.country)

    ) {
      return false;
    }
      const token1=localStorage.getItem("token")
      const id=localStorage.getItem("id")
      const imageData = JSON.parse(localStorage.getItem("imageData") || '{}');
      const user_avatar = {
        image: imageData.image || '', 
        profile_picture: imageData.profile_picture || '',
        content_type: imageData.content_type || "image/png",
        file_name: imageData.file_name || "image.png"
    };
    const header = {
      "Content-Type": "application/json",
      "token":token1
    };
    const attrs = {
      "company_number": this.state.company_number,
      "street_name": this.state.street_name,
      "vat": this.state.vat,
      "zip_code": this.state.zip_code,
      "city": this.state.city,
      "state": this.state.state,
      "country": this.state.country,
      "first_name": localStorage.getItem("first_name") || '',
      "last_name": localStorage.getItem("lastName") || '',
      "phone_number": localStorage.getItem("phoneNumber") || '',
      "country_code": localStorage.getItem("countryCode") || '+44',
      "company_name": localStorage.getItem("companyName") || '',
      "activated":"yes",
    };
    const httpBody = {
      "data": {
        "attributes": attrs,
        "user_avatar": user_avatar
      },
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCustomizableBusinessProfileCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/edit_profile`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    const response = runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getPersonalInformationAPI = () => {
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getPersonalInformation = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/profile`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  editPersonalInformationAPI = () => {
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };
    const {
      first_name,
      last_name,
      phone_number,
      role_id,
      job_title,
      about_me,
      country_code,
      company_name,
      company_number,
      vat,
      street_name,
      city,
      zip_code,
      state,
      country
    } = this.state.editPersonalInformation;

    const attributes = {
      "first_name": first_name,
      "last_name": last_name,
      "phone_number": phone_number,
      "role_id": role_id,
      "job_title": job_title,
      "about_me": about_me,
      "country_code": country_code,
      "company_name": company_name,
      "company_number": company_number,
      "vat": vat,
      "street_name": street_name,
      "city": city,
      "zip_code": zip_code,
      "state": state,
      "country": country
    };
    
    const payload = {
      "data": {
        "attributes": attributes,
        ...(this.state.editImageData.file_name && { "user_avatar": this.state.editImageData })
      }
    };  
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.editPersonalInformationId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/edit_profile`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  showMessageDuringUpdateEmailAndPassword = () => {
    const storedMessage = localStorage.getItem("message");
    const data = storedMessage ? JSON.parse(storedMessage) : null;
    if (data) {
      this.setState({ ...data }, () => {
        this.closeMessageBox();
      });
    }
    localStorage.removeItem("message");
  }

  handleTogglePIDropDown = ()=>{
    if(!this.state.editDetailToggle)
    this.setState((prevState) => ({
      piToggleDropDown: !prevState.piToggleDropDown,
    }));
  }
  handleToggleRolePIDropDown = () => {
    if (!this.state.editDetailToggle)
    this.setState((prevState) => ({
        piToggleRoleDropDown: !prevState.piToggleRoleDropDown,
    }));
  }
  navigatePage =   ( toggle:boolean ) => {
    localStorage.setItem("page", toggle.toString());
     this.props.navigation.navigate("Settings2");
  }
  viewFullScreen = (img:string) => {
    localStorage.setItem("previewImg", JSON.stringify(img));
    localStorage.setItem("stateData",JSON.stringify( this.state ));
    this.props.navigation.navigate("BulkUploading");
  }

  checkPageOpen =  () =>{
    const pageToggle = localStorage.getItem("profilePageToggle");
    const stateData = JSON.parse(localStorage.getItem("stateData") as string);
    if(pageToggle && stateData){
      this.setState( stateData );
      localStorage.removeItem("profilePageToggle");
      localStorage.removeItem("stateData");
    }
  }

  cancelAccountPopUpToggle = ()=>{
    this.setState({ 
      deleteAccountToggle:false, 
      asteriskAccountCurrent: "",
      currentAccountPassword: "",
      deleteAccountPassword:"",
      deleteAccountType:"",
      CommonDeletionAccountError:""      
    });
  }

  validateAccountType(val: string) {
    const AccountTypError = val != "DELETE ACCOUNT";
    this.setState({ AccountTypError });
  }

  handleChangeAccType = (val: string) => {
    this.validateAccountType(val);
    this.setState({ deleteAccountType: val });
  }

  handleAccountCurrentPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const password = this.state.deleteAccountPassword;
    let origi = "";

    const inputType = (e.nativeEvent as InputEvent).inputType;

    if (inputType === "deleteContentBackward") {
      origi = password.slice(0, password.length - 1);
    } else {
      origi = password + value[value.length - 1];
    }

    this.setState({
      asteriskAccountCurrent: "*".repeat(origi.length),
      currentAccountPassword: value,
      deleteAccountPassword: origi
    });
  };

  toggleAccountVisibilityToggle = () => {
    this.setState(prevState => ({
      showAccountCurrentPassword: !prevState.showAccountCurrentPassword
    }));
  }

  desiableAccountCheck = () => {
    let AccountTypError = !this.state.AccountTypError;
    let deleteAccountType = this.state.deleteAccountType != "";
    let currentPasswordError = this.state.currentPasswordError == "";
    let deleteAccountPassword = this.state.deleteAccountPassword != "";

    return AccountTypError && currentPasswordError && deleteAccountType && deleteAccountPassword;
  }

  deleteAccount = () => {
    this.setState({ deleteAccountToggle: true });
  }

  checkCurrentPassword= ()=>{
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const payload = {
      "data": {
          "current_password": this.state.deleteAccountPassword,
      }
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.currentPasswordCheckId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_forgot_password/passwords/current_password_match`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async componentWillUnmount() {
    if (this.delayInputTimeoutId) {
      clearTimeout(this.delayInputTimeoutId);
    }
  }

  deleteAccountApiCall = ()=> {
    const token1 = localStorage.getItem("token");
    const header = {
      "Content-Type": "application/json",
      "token": token1
    };

    const payload = {
          "password": this.state.deleteAccountPassword,
          "confirmation_phrase": this.state.deleteAccountType
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteAccountId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_settings/settings/delete_account`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCondition = (condition:any,truePart:any,falsePart:any)=>{
     return condition ? truePart : falsePart
  }
   
  // Customizable Area End
}
