// Customizable Area Start
import React from "react";

import EditIcon from "@material-ui/icons/Edit";
import { IconButton } from "@mui/material";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from "@material-ui/icons/Search";
import MyProfileHeader from "../../../components/src/MyProfileHeader";
import { image, checkedIcon, success, unCheckIcon, createIcon ,accountDel,arrows} from "./assets";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";
import AddNewMemberController, { Props } from "./AddNewMemberController";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
  Box,
  Button,
  Typography,
  TextField,
  Modal,
  ListItemText,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  CardMedia,
  InputAdornment,
  Checkbox,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  InputLabel,
  Select as MUISelect,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Select from "react-select";
import { debounce } from "lodash";
import { ToastContainer } from "react-toastify";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});
export default class AddNewMember extends AddNewMemberController {
  constructor(props: Props) {
    super(props);
  }

  renderSelectInput() {
    return (
      <>
        <Box mt="1.5rem" style={webStyle.InputSearch} position="relative">
          <style>
            {`
              .basic-multi-select {
                width: 100%;
              }
            `}
          </style>
          <Select
            value={this.state.selectedUsers}
            isMulti
            name="members"
            options={this.filterUsers(
              this.state.users,
              this.state.selectedUsers
            )}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{
              control: (provided, state) => {
                const borderColor = this.state.memberError
                  ? "2px solid red"
                  : "1px solid #ccc";

                return {
                  ...provided,
                  display: "flex",
                  flexWrap: "wrap",
                  padding:
                    this.state.selectedUsers.length > 0 ? "0.3rem" : "0.9rem",
                  border: borderColor, 
                  borderRadius: "8px",
                  paddingLeft: "1rem",
                  boxShadow: "none",
                  "&:hover": {
                    border: borderColor, 
                  },
                };
              },
              multiValue: (provided) => ({
                ...provided,
                marginRight: "5px",
                display: "flex",
                alignItems: "center",
                borderRadius: "26px",
                background: "#E0EDF0",
                padding: "6px 12px",
                fontSize: "14px",
                color: "#325962",
                fontFamily: "Poppins",
                fontWeight: 400,
              }),
              input: (provided) => ({
                ...provided,
                margin: 0,
                padding: "0 6px",
                width: "auto",
                flexGrow: 1,
              }),
              placeholder: (provided) => ({
                ...provided,
                fontFamily: "Poppins",
                paddingLeft: "6px",
              }),
            }}
            components={{
              DropdownIndicator: () => null,
              ClearIndicator: () => null,
            }}
            placeholder="Search and add members"
            menuIsOpen={false}
            onChange={(selectedOptions) => {
              this.setState({
                selectedUsers: selectedOptions || [],
                memberError: selectedOptions ? "" : this.state.memberError,
              });
            }}
            onInputChange={this.handleInputChange}
            data-test-id="select-id"
          />

          <SearchIcon
            style={{
              position: "absolute",
              left: "1%",
              top: "54%",
              transform: "translateY(-50%)",
              color: "grey",
            }}
          />
          <RenameButton
            data-test-id="create-id"
            style={{ marginLeft: "16px" }}
            onClick={this.handleAddMemberData}
          >
            Add
          </RenameButton>
        </Box>
        {this.state.memberError && (
          <Box color="red" mt="0.5rem" data-test-id="error-message">
            {this.state.memberError}
          </Box>
        )}
      </>
    );
  }

  renderMemberList() {
    const { users, selectedUsers, inputName } = this.state;
    const filteredUsers = this.filterAvailableUsers(users, selectedUsers, inputName);
  
    return (
      <Box style={{ maxHeight: "350px", overflowY: "auto", marginTop: "1rem" }}>
        {filteredUsers.length === 0 ? (
          <Typography
            style={{ color: "#1E293B", fontSize: "16px", textAlign: "center" }}
          >
            No members present
          </Typography>
        ) : (
          <List>
            {filteredUsers.map((user) => (
              <ListItem
                key={user.id}
                style={{
                  paddingLeft: "0px",
                  paddingRight: "0px",
                  paddingBottom: "0px",
                }}
              >
                <ListItemAvatar>
                  <Avatar
                    style={{ background: "#D3E4E9", color: "#ABCDD5" }}
                  >
                    <span
                      style={{
                        fontSize: "18px",
                        fontWeight: 700,
                        paddingTop: "4px",
                      }}
                    >
                      {user.user_name.slice(0, 2).toUpperCase()}
                    </span>
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      variant="subtitle1"
                      style={webStyle.ListItemName}
                    >
                      {user.user_name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      style={webStyle.ListItemEmail}
                    >
                      {user.email}
                    </Typography>
                  }
                />
                <IconButton
                  data-test-id="memberAdd-id"
                  onClick={() => this.handleMemberAdd(user)}
                  style={{ color: "#000", opacity: 1 }}
                >
                  <AddIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    );
  }
  
  renderMembersList = () => {
    const { searchList } = this.state;
    if (
      searchList &&
      searchList.length > 0 &&
      this.state.membersList.length !== 0
    ) {
      return searchList.map((row, index) => (
        <TableRow key={index}>
          <TableCell
            className="SubDivC"
            style={{ fontFamily: "Poppins" }}
          >{`${row.attributes.first_name} ${row.attributes.last_name}`}</TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.email}
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.job_title}
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            <Typography
              style={{
                height: "20px",
                width: "fit-content",
                borderRadius: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBlock: "2px",
                paddingInline: "8px",
                fontWeight: 700,
                fontSize: "12px",
                textTransform: "uppercase",
                fontFamily: "Poppins",
                ...this.getRoleStyles(row.attributes.role),
              }}
            >
              {row.attributes.role_id}
            </Typography>
          </TableCell>
          <TableCell className="SubDivC" style={{ fontFamily: "Poppins" }}>
            {row.attributes.activated ? "Activated" : "Not Activated"}
          </TableCell>
          <UpdatedBox>
            <TableCell style={{ display: "flex", justifyContent: "end" }}>
              <Typography
                data-test-id="icon"
                className="hoverEffect"
                onClick={(event) => this.handleClick(event, row)}
              >
                <MoreVertIcon style={{ cursor: "pointer" }} />
              </Typography>

              <Popover
                style={webStyle.popup}
                open={Boolean(this.state.anchorEl)}
                anchorEl={this.state.anchorEl}
                onClose={this.handleClose}
                data-test-id="handleClose-id"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                PaperProps={{
                  style: {
                    padding: "10px",
                    width: "213px",
                    boxShadow: "0px 4px 8px 0px #00000008",
                    marginLeft: "-3.5rem",
                    borderRadius: "8px",
                  },
                }}
              >
                <MenuItem
                  style={{
                    ...webStyle.popMenu,
                    marginBottom: "10px",
                  }}
                  data-test-id="edit"
                >
                  {" "}
                  <span style={webStyle.iconM}>
                    <EditIcon />
                  </span>{" "}
                  Edit details
                </MenuItem>
                <MenuItem
                  style={{
                    ...webStyle.popMenu,
                    marginBottom: "10px",
                  }}
                  data-test-id="rename"
                  onClick={() => this.handleRenameModal()}
                >
                  <span style={webStyle.iconM}>
                    <img src={arrows} alt="rename" />
                  </span>{" "}
                  Move Member
                </MenuItem>
                <MenuItem
                  style={webStyle.deleteIcon}
                  data-test-id="delete"
                  onClick={()=>{this.handleRemoveMember()}}
                >
                  <span style={webStyle.iconM}>
                  
                  <img src={accountDel} alt="accountDel" />
                  </span>
                  Remove Member
                </MenuItem>
              </Popover>
            </TableCell>
          </UpdatedBox>
        </TableRow>
      ));
    } else
      return (
        <TableRow>
          <TableCell
            colSpan={5}
            style={{ textAlign: "center", fontFamily: "Poppins" }}
          >
            No members found
          </TableCell>
        </TableRow>
      );
  };

  renderDeleteTeamModal = () =>{
    return(
      <Modal
      open={this.state.isDeleteMemberModalOpen}
      data-test-id="handleDeleteModal"
      onClose={() => this.handleRemoveMember()}
      style={{ display: "flex" }}
    >
      <Box style={webStyle.renameModalDelete}>
        <Box style={webStyle.modalHeaderDelete}>
          <Typography style={webStyle.renameText}>
          Are you sure you want to remove {`${this.state.selectedRow?.attributes.first_name } ${this.state.selectedRow?.attributes.last_name ?? ""}`.trim()} from this team?
          </Typography>
          <Close
            onClick={() => this.handleRemoveMember()}
            style={{ cursor: "pointer" }}
            data-test-id="close-btn"
          />
        </Box>
        <Typography style={{ color: "#1E293B", width: "90%" }}>
          Removing [{`${this.state.selectedRow?.attributes.first_name } ${this.state.selectedRow?.attributes.last_name}`.trim()}] from the team will revoke their access and permissions within the team.
        </Typography>

        <Box style={webStyle.modalButtons}>
          <RenameButton
            style={webStyle.cancelButton}
            onClick={() => this.handleRemoveMember()}
            data-test-id="cancel-btn"
          >
            Cancel
          </RenameButton>
          <RenameButton
            onClick={this.handleRemoveMemberApi}
            data-test-id="removeMember-id"
          >
            Remove
          </RenameButton>
        </Box>
      </Box>
    </Modal>
    )
  }

  renderModal() {
    return (
      <Modal open={this.state.isAddMemberModalOpen} style={{ display: "flex" }}>
        <Box style={webStyle.renameModal}>
          <Box style={webStyle.modalHeader}>
            <Typography style={webStyle.renameText}>
              Add Members to Your Team
            </Typography>
            <Close
              data-test-id="close-data-id"
              onClick={this.handleAddMemberModal}
              style={{ cursor: "pointer" }}
            />
          </Box>
          <Typography style={{ color: "#1E293B" }}>
            Add team members by entering their email addresses below.
          </Typography>
          {this.renderSelectInput()}
          {this.renderMemberList()}
        </Box>
      </Modal>
    );
  }

  renderRenameTeamModal = () =>{
    return(
      <Modal  data-test-id="rename-data-id"
       onClose={() => this.handleRenameModal()}
      open={this.state.isRenameModalOpen}
      style={{ display: "flex" }}
     
    >
      <Box style={webStyle.renameModal as React.CSSProperties} >
        <Box style={webStyle.modalHeader}>
          <Typography style={webStyle.renameText}>Rename Team</Typography>
          <Close
          data-test-id="modeling"
            onClick={() => this.handleRenameModal()}
            style={{ cursor: "pointer" }}
            
          />
        </Box>
        <Typography style={{ color: "#1E293B" }}>Choose a new name for your team to help organize members
          effectively.
        </Typography>
        <Box mt="1.5rem">
          <InputLabel style={webStyle.renameInputLabel}>
            Team Name
          </InputLabel>
          <RenameTextField   value={this.state.teamRename} 
             error= {this.state.teamRenameBlankError || this.state.teamNameRenameError}
            variant="outlined"
            name="teamName"
            data-test-id="handleteamName-id"
            placeholder="Enter team name"
    
            onChange={(e) => this.handleTeamNameChange(e.target.value)}
            fullWidth 
            required
            inputProps={{
              style: {
                fontFamily: "'Poppins', sans-serif",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "8px",
              },
            }}
          />
           {this.state.teamRenameBlankError && (
            <Typography style={webStyle.errorText}>  The team name cannot be blank. 
            </Typography>
          )}
               {this.state.teamNameRenameError && (
            <Typography style={webStyle.errorText}> This team name is already in use. Please choose a different
              name.
            </Typography>
          )}
        </Box>
        <Box style={webStyle.modalButtons}>
          <RenameButton
            style={webStyle.cancelButton}
            data-test-id="cancel-id"
            onClick={() => this.handleRenameModal()}  >   Cancel
          </RenameButton>
          <RenameButton
            disabled={!this.state.teamName}
            onClick={this.editTeamNameApi}
            data-test-id="rename-btn"
          >
            Rename
          </RenameButton>
        </Box>
      </Box>
    </Modal>
    )
  }

  renderActivatedCheckbox = () => {
    const { isApproveOpen, activated } = this.state;
    return (
      <CustomCheckbox
        data-test-id="approve-checkbox"
        open={isApproveOpen}
        onOpen={() => this.setState({ isApproveOpen: true })}
        onClose={() => this.setState({ isApproveOpen: false })}
        IconComponent={() => {
          return isApproveOpen ? (
            <ExpandLess onClick={() => this.setState({ isApproveOpen: false })} />
          ) : (
            <ExpandMore onClick={() => this.setState({ isApproveOpen: true })} />
          );
        }}
        style={{ width: "137px" }}
        multiple
        value={activated}
        onChange={this.handleChange}
        name="activated"
        variant="outlined"
        placeholder="Activated"
        displayEmpty
        renderValue={() => "Activated"}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
          getContentAnchorEl: null,
        }}
      >
        {["Activated", "Not Activated"].map((activate) => (
          <CustomListItem key={activate} value={activate}>
            <Checkbox
              icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />}
              checkedIcon={
                <img src={checkedIcon} style={{ width: 16, height: 16 }} />
              }
              checked={activated.indexOf(activate) > -1}
            />
            <ListItemText primary={activate} />
          </CustomListItem>
        ))}
      </CustomCheckbox>
    );
  };
  

  render() {
    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader navigation={this.props.navigation}>
          {!!this.state.messageToggle && (
            <MessageBox
              data-test-id="closeBox-id"
              message={this.state.tostifymessage}
              status={this.state.status}
            />
          )}
          <Loader loading={this.state.loading} />
          <Box style={{ background: "rgb(240, 245, 247)" }}>
            {this.state.membersList.length === 0 && !this.state.isAddNot ? (
              <Box>
                <Box data-test-id="txtInput" style={webStyle.flex}>
                  <Box style={webStyle.mainContainer}>
                    <Box style={webStyle.TopContainer}>
                      <Button
                        data-test-id="backed-page"
                        style={webStyle.BackBtn}
                        onClick={this.backBtn}
                      >
                        <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp;
                        Back
                      </Button>
                      <Box style={webStyle.TeamName}>
                       <Box> {this.state.historyLocationState}</Box>
                        <IconButton  onClick={()=>{this.handleRenameModal()}}>
                          <EditIcon  />
                        </IconButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box sx={webStyle.cardStyle}>
                  <CardMedia
                    component="img"
                    style={webStyle.image}
                    image={image}
                  />
                  <Box sx={webStyle.CardContent}>
                    <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
                      No Members Added
                    </Box>
                    <Box style={{ textAlign: "center", fontFamily: "Poppins" }}>
                      Add members to start collaborating.
                    </Box>
                    <Button
                      data-test-id="btnAddExample"
                      onClick={this.handleOpenModal}
                      variant="contained"
                      color="primary"
                      style={{
                        backgroundColor: "#237182",
                        color: "white",
                        fontFamily: "Poppins",
                        fontWeight: 600,
                        fontSize: "16px",
                        borderRadius: "8px",
                        padding: "10px 16px 10px 16px",
                        textTransform: "capitalize",
                      }}
                    >
                      <span style={webStyle.createIcon}>
                        <img src={createIcon} alt="createIcon" />
                      </span>
                      Add Members
                    </Button>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box>
                {!this.state.loading?
              <Box>
                  <Box data-test-id="txtInput" style={webStyle.flex}>
                  <Box style={webStyle.mainContainer}>
                    <Box style={webStyle.TopContainer}>
                      <Button
                        data-test-id="back-page"
                        style={webStyle.BackBtn}
                        onClick={this.backBtn}
                      >
                        <span style={{ fontSize: "20px" }}> &#60; </span>&nbsp;
                        Back
                      </Button>
                      <Box style={webStyle.TeamName}>
                        {this.state.teamName}
                        <IconButton onClick={()=>{this.handleRenameModal()}}>
                          <EditIcon />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography style={webStyle.teamSize}>
                        {this.state.membersList.length + " Members"}
                      </Typography>
                      <Button
                        data-test-id="btnAddExample"
                        onClick={this.handleOpenModal}
                        variant="contained"
                        color="primary"
                        style={{
                          backgroundColor: "#237182",
                          color: "white",
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "16px",
                          borderRadius: "8px",
                          padding: "10px 16px 10px 16px",
                          textTransform: "capitalize",
                        }}
                      >
                        <span style={webStyle.createIcon}>
                          <img src={createIcon} alt="createIcon" />
                        </span>
                        Add Members
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box style={webStyle.flex}>
                  <Box style={webStyle.searchBox}>
                    <TextField
                      variant="outlined"
                      placeholder="Search here..."
                      data-test-id="search-input"
                      onChange={debounce(
                        (e) => this.getMembersListSelectApi(e.target.value),
                        1000
                      )}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon
                              style={{
                                color: "#94A3B8",
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          width: "440px",
                          height: "32px",
                          borderRadius: "8px",
                        },
                        classes: {
                          root: "textFieldRoot",
                          input: "textFieldInput",
                        },
                      }}
                      style={{
                        marginRight: "12px",
                        backgroundColor: "white",
                        height: "32px",
                        borderRadius: "8px",
                      }}
                    />

                    <Box style={{ display: "flex", gap: "12px" }}>
                      <CustomCheckbox
                        data-test-id="role-checkbox"
                        open={this.state.isRoleOpen}
                        onOpen={() => this.setState({ isRoleOpen: true })}
                        onClose={() => this.setState({ isRoleOpen: false })}
                        IconComponent={() => {
                          return this.state.isRoleOpen ? (
                            <ExpandLess
                              onClick={() =>
                                this.setState({ isRoleOpen: false })
                              }
                            />
                          ) : (
                            <ExpandMore
                              onClick={() =>
                                this.setState({ isRoleOpen: true })
                              }
                            />
                          );
                        }}
                        multiple
                        value={this.state.selectedRoles}
                        name="role"
                        onChange={this.handleChange}
                        variant="outlined"
                        placeholder="Role"
                        displayEmpty
                        renderValue={() => "Role"}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {["Owner", "Manager", "Employee"].map((role) => (
                          <CustomListItem key={role} value={role}>
                            <Checkbox
                              icon={
                                <img
                                  src={unCheckIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                              checkedIcon={
                                <img
                                  src={checkedIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                              style={{ color: "#64748B" }}
                              checked={
                                this.state.selectedRoles.indexOf(role) > -1
                              }
                            />
                            <ListItemText primary={role} />
                          </CustomListItem>
                        ))}
                        <CustomListItem value="clear">
                          <ListItemText
                            primaryTypographyProps={{
                              style: webStyle.clearAll,
                            }}
                          >
                            Clear All
                          </ListItemText>
                        </CustomListItem>
                      </CustomCheckbox>
                      <CustomCheckbox
                        data-test-id="team-checkbox"
                        open={this.state.isTeamOpen}
                        onOpen={() => this.setState({ isTeamOpen: true })}
                        onClose={() => this.setState({ isTeamOpen: false })}
                        IconComponent={() => {
                          return this.state.isTeamOpen ? (
                            <ExpandLess
                            data-test-id="expandLess"
                              onClick={() =>
                                this.setState({ isTeamOpen: false })
                              }
                            />
                          ) : (
                            <ExpandMore
                            data-test-id="expandMore"

                              onClick={() =>
                                this.setState({ isRoleOpen: true })
                              }
                            />
                          );
                        }}
                        style={{ width: "136px" }}
                        multiple
                        value={this.state.selectedTeam}
                        name="job"
                        onChange={this.handleChange}
                        variant="outlined"
                        placeholder="Job Title"
                        displayEmpty
                        renderValue={() => "Job Title"}
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                          getContentAnchorEl: null,
                        }}
                      >
                        {["full stack", "qa"].map((job) => (
                          <CustomListItem key={job} value={job}>
                            <Checkbox
                              icon={
                                <img
                                  src={unCheckIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                              checkedIcon={
                                <img
                                  src={checkedIcon}
                                  style={{ width: 16, height: 16 }}
                                />
                              }
                              checked={
                                this.state.selectedTeam.indexOf(job) > -1
                              }
                            />
                            <ListItemText primary={job} />
                          </CustomListItem>
                        ))}
                      </CustomCheckbox>
                    {this.renderActivatedCheckbox()}
                    </Box>
                  </Box>

                  <Box
                    style={{
                      alignItems: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "0",
                      }}
                    >
                      <Box style={webStyle.sort}>
                        {" "}
                        <Typography variant="body1">Sort By:</Typography>
                      </Box>
                      <Box>
                      <select
  style={{
    border: "none",
    color: "#237182",
    fontWeight: 600,
    fontSize: "16px",
    backgroundColor: "#f0f5f7",
    marginBottom: "2px",
    marginRight: "5px",
  }}
  name="sort"
  value={this.state.selectedOrder}
  onChange={this.handleChange}      
>
  <option value="">Name</option>  
  <option value="a-z">A-Z</option>   
  <option value="z-a">Z-A</option>   
</select>

                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box style={webStyle.mt10}>
                  <TableContainer style={webStyle.SubTableBox}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell style={webStyle.TableTitleText}>
                            Name
                          </TableCell>
                          <TableCell style={webStyle.TableTitleText}>
                            Email
                          </TableCell>
                          <TableCell style={webStyle.TableTitleText}>
                            Job Title
                          </TableCell>
                          <TableCell style={webStyle.TableTitleText}>
                            Role
                          </TableCell>
                          <TableCell style={webStyle.TableTitleText}>
                            Status
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>{this.renderMembersList()}</TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="0px"
                  style={webStyle.footer}
                >
                  <Typography></Typography>
                  <Box style={webStyle.footer2}>
                    <ChevronLeftIcon style={webStyle.footerIcon} />{" "}
                    <Typography style={webStyle.footerFont}>1</Typography>
                    <ChevronRightIcon style={webStyle.footerIcon} />
                  </Box>
                  <Typography style={webStyle.footer3}>
                    <Typography style={webStyle.footer3}>
                      1 -{this.state.membersList.length}
                      of
                      {this.state.membersList.length}
                      results
                    </Typography>
                  </Typography>
                </Box>
              </Box>
                : ""}
              </Box>
            )}

            <Box></Box>
          </Box>
          {this.renderModal()}
             {this.renderRenameTeamModal()}
          {this.renderDeleteTeamModal()}
          <CustomToast icon={<img src={success} />} />
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}

const CustomToast = styled(ToastContainer)({
  width: "40vw",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});

const UpdatedBox = styled(Box)({
  "& .hoverEffect": {
    padding: "3px",
    "&:hover": {
      backgroundColor: "#D3E4E9",
      borderRadius: "50%",
    },
  },
});

const CustomCheckbox = styled(MUISelect)({
  borderRadius: "8px",
  height: "32px",
  minWidth: "123px",
  backgroundColor: "#fff",
  "&:hover": {
    backgroundColor: "#fff",
  },
  "& .MuiSvgIcon-root": {
    marginRight: "7px",
    height: "20px",
    width: "20px",
    cursor: "pointer",
  },
  "& .MuiOutlinedInput-input": {
    padding: "7.5px 14px",
  },
});

const CustomListItem = styled(MenuItem)({
  "&.Mui-selected": {
    backgroundColor: "transparent",
  },
  "&.Mui-selected:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&:hover": {
    backgroundColor: "#E0EDF0",
  },
  "&.MuiMenuItem-root": {
    paddingLeft: "0px",
    paddingRight: "8px",
  },
});

const webStyle: any = {
  BackBtn: {
    color: "#334155",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
  },
  TeamName: {
    fontSize: "24px",
    fontWeight: "700",
    fontFamily: "poppins",
    paddingLeft: "1rem",
    display:"flex"
  },

  TopContainer: {
    display: "flex",
    alignItems: "center",
    alignContent: "center",
    padding: "6px 8px",
  },
  createIcon: {
    marginRight: "10px",
  },
  image: {
    width: "350px",
    height: "350px",
  },
  cardStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5rem",
  },
  CardContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "1rem",
  },
  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  renameModalDelete: {
    margin: "auto",
    width: "584px",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  modalHeaderDelete: {
    display: "flex",
    color: "#0F172A",
    marginBottom: "1rem",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },

  InputSearch: {
    position: "relative",
    display: "flex",
    justfiyContent: "space-between",
    alignItems: "center",
  },
  ListItemName: {
    fontSize: "16px",
    fontFamily: "poppins",
    lineHeight: "24px",
    fontWeight: "400",
  },
  ListItemEmail: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "22px",
    fontWeight: "400",
  },
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "white",
    borderRadius: "50px",
    padding: "4px",
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px",
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "16px",
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "27px",
    margin: "0px",
    fontSize: "16px",
    ...(window.innerWidth < 600 && {
      textAlign: "center",
    }),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 29px 0px 15px",
    marginTop: "20px",
    backgroundColor: "#f0f5f7",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  createTeamBtn: {
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    textTransform: "capitalize",
  },
  searchBox: {
    display: "flex",
  },
  sort: {
    marginRight: "15px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  mt10: {
    marginTop: "20px",
    paddingLeft: "16px",
    paddingRight: "30px",
  },
  teamSize: {
    marginRight: "15px",
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "white",
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "22%",
  },
  TableHeaderCenter: {
    textAlign: "center" as "center",
  },
  TableContentBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  SubBoxy: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
  },

  SubUserImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  SubDivA: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
  },

  SubDivI: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "2px 8px",
    borderRadius: "40px",
  },
  deleteIcon: {
    color: "#DC2626",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  iconM: {
    marginRight: "10px",
    fontSize: "10px",
  },
  popMenu: {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  popup: {
    padding: "20px !important",
    marginLeft: "-5px !important",
  },
  footer: {
    padding: "10px",
  },
  footer2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#94A3B8",
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  footerFont: {
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginRight: "30px",
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },

  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  clearAll: {
    textAlign: "center" as "center",
    color: "#237182",
    fontWeight: 600,
  },
  mainContainer: {
    display: "flex",
    alignContent: "center",
    width: "1000%",
    justifyContent: "space-between",
  },
};

// Customizable Area End
