// Customizable Area Start
import React from "react";

import {
  Box,
  Button,
  Typography,
  InputAdornment,
  TextField,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Modal,
  InputLabel,
  Select,
  Checkbox,
  ListItemText
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import Loader from "../../../components/src/Loader.web";
import Close from "@material-ui/icons/Close";
import SearchIcon from '@material-ui/icons/Search';
import MyProfileHeader from "../../../components/src/MyProfileHeader"
import { rename,createIcon,unCheckIcon, checkedIcon, success} from "./assets";
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import TeamBuilderController, {
  Props,
} from "./TeamBuilderController";
import { debounce } from "lodash";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import MessageBox from "../../../components/src/messageBox";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    body1: {
      fontFamily: "Poppins",
    },
  },
});

const RenameButton = styled(Button)({
  padding: "1rem",
  borderRadius: "8px",
  fontFamily: "Poppins",
  textTransform: "none",
  background: "#237182",
  color: "#FFF",
  fontWeight: 600,
  "&:disabled": {
    background: "#F1F5F9",
    color: "#64748B",
  },
  "&:hover": {
    background: "#237182",
  },
});

const RenameTextField = styled(TextField)({
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "rgba(0, 0, 0, 0.5)",
  },
});




export default class TeamBuilder extends TeamBuilderController {
  constructor(props: Props) {
    super(props);
  }
  renderMembersList = () => {
    const { membersList } = this.state;
  
    if (membersList.length > 0) {
      return membersList.map((row, index) => (
        <TableRow key={index}>
          <TableCell style={{...webStyle.SubDivC}}>{row.user_name}</TableCell>
          <TableCell style={{...webStyle.SubDivC}}>{row.email}</TableCell>
          <TableCell style={{...webStyle.SubDivC}}>{row.teams}</TableCell>
          <TableCell style={{...webStyle.SubDivC}}>
            <Typography
              style={{
                height: '20px',
                width: 'fit-content',
                borderRadius: '35px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingBlock: '2px',
                paddingInline: '8px',
                fontWeight: 700,
                fontSize: '12px',
                fontFamily:'Poppins',
                textTransform: 'uppercase',
                ...this.getRoleStyles(row.role),
              }}
            >
              {row.role}
            </Typography>
          </TableCell>
          <TableCell style={{...webStyle.SubDivC}}>{row.status}</TableCell>
        </TableRow>
      ));
    } else {
      return (
        <TableRow>
          <TableCell colSpan={5} style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
            No members found
          </TableCell>
        </TableRow>
      );
    }
  };

  renderCreateTeamModal =()=>{
return(
  <Modal open={this.state.isCreateTeamModalOpen} 
  onClose={this.handleCreateTeamModal}
  style={{ display: "flex" }}
  data-test-id="modal"
>
<Box style={webStyle.renameModal}>
<Box style={webStyle.modalHeader}>
 <Typography style={webStyle.renameText}>Create New Team</Typography>
 <Close 
   data-test-id="close-data-id"
   onClick={() => this.handleCreateTeamModal()}
   style={{ cursor: "pointer" }}
 />
</Box>
<Typography style={{ color: "#1E293B" }}>
Enter the name for your team to help organize members effectively.
</Typography>
<Box mt="1.5rem">
 <InputLabel style={webStyle.renameInputLabel}>
 Team name      
 </InputLabel>
 <RenameTextField
 style={{
   paddingTop:"6px"
 }}
   error={this.state.teamNameError || this.state.blankError} 
   value={this.state.teamName}
   variant="outlined"
   name="teamName"
   data-test-id="teamName"
   onChange={(e) => this.handleCreateTeamName(e.target.value)}
   fullWidth
   placeholder="Enter team name"
   required
   inputProps={{
     style: {
       fontFamily: "'Poppins', sans-serif"
     },
   }}
   InputProps={{
     style: {
       borderRadius: "8px",
     },
   }}
 />
 {this.state.teamNameError && (
   <Typography style={webStyle.errorText}>
     This team name is already in use. Please choose a different
     name.
   </Typography>
 )}
 {this.state.blankError && (
   <Typography style={webStyle.errorText}>
      The team name cannot be blank.
   </Typography>
 )}
</Box>
<Box style={webStyle.modalButtons}>
 <RenameButton
   data-test-id="cancelBtn-id"
   style={webStyle.cancelButton}
   onClick={() => this.handleCreateTeamCancelButton()}
 >
   Cancel
 </RenameButton>
 <RenameButton
   disabled={!this.state.teamName}
    data-test-id="create-id"
   onClick={this.handleCreateTeamSubmit}
  
 >
 Create
 </RenameButton>
</Box>
</Box>
</Modal>
)
  }
  
  renderDeleteTeamModal = () =>{
    return(
      <Modal
      open={this.state.isDeleteModalOpen}
      data-test-id="handleDeleteModal"
      onClose={() => this.handleDeleteModal()}
      style={{ display: "flex" }}
    >
      <Box style={webStyle.renameModal}>
        <Box style={webStyle.modalHeader}>
          <Typography style={webStyle.renameText}>
            Are you sure you want to delete team?
          </Typography>
          <Close
            onClick={() => this.handleDeleteModal()}
            style={{ cursor: "pointer" }}
            data-test-id="close-btn"
          />
        </Box>
        <Typography style={{ color: "#1E293B" }}>
          Deleting {this.state.selectedRow?.name} will permanently remove
          all data from our system.
        </Typography>
        <Box style={webStyle.modalButtons}>
          <RenameButton
            style={webStyle.cancelButton}
            onClick={() => this.handleDeleteModal()}
            data-test-id="cancel-btn"
          >
            Cancel
          </RenameButton>
          <RenameButton
            onClick={this.deleteTeamApi}
            data-test-id="delete-btn"
          >
            Delete
          </RenameButton>
        </Box>
      </Box>
    </Modal>
    )
  }

  renderRenameTeamModal = () =>{
    return(
      <Modal
      open={this.state.isRenameModalOpen}
      onClose={() => this.handleRenameModal()}
      style={{ display: "flex" }}
    >
      <Box style={webStyle.renameModal as React.CSSProperties} >
        <Box style={webStyle.modalHeader}>
          <Typography style={webStyle.renameText}>Rename Team</Typography>
          <Close
            onClick={() => this.handleRenameModal()}
            style={{ cursor: "pointer" }}
          />
        </Box>
        <Typography style={{ color: "#1E293B" }}>
          Choose a new name for your team to help organize members
          effectively.
        </Typography>
        <Box mt="1.5rem">
          <InputLabel style={webStyle.renameInputLabel}>
            Team Name
          </InputLabel>
          <RenameTextField
            error={this.state.teamNameRenameError || this.state.teamRenameBlankError} 
            value={this.state.teamName}
            variant="outlined"
            name="teamName"
            data-test-id="teamName"
            onChange={(e) => this.handleTeamNameChange(e.target.value)}
            fullWidth
            placeholder="Enter team name"
            required
            inputProps={{
              style: {
                fontFamily: "'Poppins', sans-serif",
              },
            }}
            InputProps={{
              style: {
                borderRadius: "8px",
              },
            }}
          />
          {this.state.teamNameRenameError && (
            <Typography style={webStyle.errorText}>
              This team name is already in use. Please choose a different
              name.
            </Typography>
          )}
           {this.state.teamRenameBlankError && (
            <Typography style={webStyle.errorText}>
                The team name cannot be blank. 
            </Typography>
          )}
        </Box>
        <Box style={webStyle.modalButtons}>
          <RenameButton
            style={webStyle.cancelButton}
            onClick={() => this.handleRenameModal()}
          >
            Cancel
          </RenameButton>
          <RenameButton
            disabled={!this.state.teamName}
            onClick={this.editTeamNameApi}
            data-test-id="rename-btn"
          >
            Rename
          </RenameButton>
        </Box>
      </Box>
    </Modal>
    )
  }

  render() {

    return (
      <ThemeProvider theme={theme}>
        <MyProfileHeader
          navigation={this.props.navigation}
          tabName="Users&Teams"
        >
 {this.state.messageToggle && <MessageBox message={this.state.tostifymessage} status={this.state.status}/>}

          <Loader loading={this.state.loading} />
          <Box data-test-id="txtInput" style={webStyle.flex}>
            <Box component="div" style={webStyle.OptionBox}>
              <Box component="div" style={webStyle.singleOptionBox}>
                <Box
                  data-test-id="button1"
                  onClick={this.handleToggle}
                  component="div"
                  style={
                    {
                      ...webStyle.PersonalInformationOption,
                      backgroundColor: this.state.toggle ? "#E0EDF0" : "",
                    } as React.CSSProperties
                  }
                >
                  Teams
                </Box>
                <Box
                  data-test-id="button2"
                  onClick={this.handleToggle}
                  component="div"
                  style={
                    {
                      ...webStyle.BusinessInformationOption,
                      backgroundColor: this.state.toggle ? "" : "#E0EDF0",
                    } as React.CSSProperties
                  }
                >
                  Members
                </Box>
              </Box>
            </Box>
            <Box>
              <Box
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography style={webStyle.teamSize}>
                  {this.state.toggle
                    ? this.state.rows.length + " Teams"
                    : this.state.membersList.length + " Members"}
                </Typography>
                <Button
                  data-test-id="btnAddExample"
                  onClick={this.handleCreateTeamModal}
                  variant="contained"
                  color="primary"
                  style={{
                    backgroundColor: "#237182",
                    color: "white",
                    fontFamily: "Poppins",
                    fontWeight: 600,
                    fontSize: "16px",
                    borderRadius: "8px",
                    padding: "10px 16px 10px 16px",
                    textTransform: "capitalize",
                  }}
                >
                  <span style={webStyle.createIcon}>
                    <img src={createIcon} alt="createIcon" />
                  </span>
                  Create Team 
                </Button>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.flex}>
            <Box style={webStyle.searchBox}>
              <TextField
                variant="outlined"
                placeholder="Search here..."
                data-test-id="search-input"
                onChange={
                  this.state.toggle
                    ? debounce(
                        (e) => this.getTeamsListApi(e.target.value),
                        1000
                      )
                    : debounce(
                        (e) => this.getMembersListApi(e.target.value),
                        1000
                      )
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon
                        style={{
                          color: "#94A3B8",
                          width:"20px",
                          height:"20px"}}
                      />
                    </InputAdornment>
                  ),
                  style: {
                    width: '440px',
                    height: '32px', 
                    borderRadius:"8px"
                  },
                  classes: {
                    root: 'textFieldRoot',
                    input: 'textFieldInput',
                  },
                }}
                style={{
                  marginRight: '12px',
                  backgroundColor:"white",
                  height: '32px',
                  borderRadius: '8px',
                }}
                />

            {!this.state.toggle && <Box style={{display:'flex', gap:'12px'}}>
                <CustomCheckbox
                data-test-id='role-checkbox'
                open={this.state.isRoleOpen}
                onOpen={() => this.setState({isRoleOpen: true})}
                onClose={() => this.setState({isRoleOpen: false})}
                IconComponent={()=>{
                  return this.state.isRoleOpen ? <ExpandLess onClick={()=>this.setState({isRoleOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                multiple
                value={this.state.selectedRoles}
                name="role"
                onChange={this.handleChange}
                variant="outlined"
                placeholder="Role"
                displayEmpty
                renderValue={() => 'Role'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Owner', 'Manager', 'Employee'].map((role) => (
                  <CustomListItem key={role} value={role} >
                  <Checkbox icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }} />} checkedIcon={<img src={checkedIcon} style={{ width: 16, height: 16 }} />} style={{ color: "#64748B" }} checked={this.state.selectedRoles.indexOf(role) > -1} />                   
                    <ListItemText primary={role} />
                  </CustomListItem>
                ))}
                    <CustomListItem value="clear">
                      <ListItemText
                        primaryTypographyProps={{
                          style: webStyle.clearAll,
                        }}
                      >
                        Clear All
                      </ListItemText>
                    </CustomListItem>
              </CustomCheckbox>
              <CustomCheckbox
                data-test-id='team-checkbox'
                open={this.state.isTeamOpen}
                onOpen={() => this.setState({isTeamOpen: true})}
                onClose={() => this.setState({isTeamOpen: false})}
                IconComponent={()=>{
                  return this.state.isTeamOpen ? <ExpandLess onClick={()=>this.setState({isTeamOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                style={{width:'136px'}}
                multiple
                value={this.state.selectedTeam}
                name="team"
                onChange={this.handleChange}
                variant="outlined"
                placeholder="Team"
                displayEmpty
                renderValue={() => 'Team'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Sales team', 'Marketing', 'Accounts Team', 'Sales team', 'Marketing Department'].map((team) => (
                  <CustomListItem key={team} value={team}>
                    <Checkbox icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }}/>} checkedIcon={<img src={checkedIcon} style={{ width: 16, height: 16 }}/>} checked={this.state.selectedTeam.indexOf(team) > -1} />
                    <ListItemText primary={team} />
                  </CustomListItem>
                ))}
              </CustomCheckbox>
              <CustomCheckbox
                data-test-id='approve-checkbox'
                open={this.state.isApproveOpen}
                onOpen={() => this.setState({isApproveOpen: true})}
                onClose={() => this.setState({isApproveOpen: false})}
                IconComponent={()=>{
                  return this.state.isApproveOpen ? <ExpandLess onClick={()=>this.setState({isApproveOpen: false})}/> : <ExpandMore onClick={()=>this.setState({isRoleOpen: true})}/>
                }}
                style={{ width:'137px'}}
                multiple
                value={this.state.activated}
                onChange={this.handleChange}
                name="activated"
                variant="outlined"
                placeholder="Activated"
                displayEmpty
                renderValue={()=>'Activated'}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              >
                {['Activated', 'Not Activated'].map((activate) => (
                  <CustomListItem key={activate} value={activate}>
                    <Checkbox icon={<img src={unCheckIcon} style={{ width: 16, height: 16 }}/>} checkedIcon={<img src={checkedIcon} style={{ width: 16, height: 16 }}/>} checked={this.state.activated.indexOf(activate) > -1} />
                    <ListItemText primary={activate} />
                  </CustomListItem>
                ))}
              </CustomCheckbox>
              </Box>}
            </Box>

            <Box style={{
              alignItems:"center",
              display:"flex",
              justifyContent:"center"
            }}>
              <Box style={{ display: "flex", alignItems: "center" ,gap:"0"}}>
                <Box style={webStyle.sort}> <Typography variant="body1">Sort By:</Typography></Box>
                <Box >
    

<Box style={{ position: 'relative', display: 'inline-block' }}>
  <select style={{
      border: "none",
      color: "#237182",
      fontWeight: 600,
      fontFamily: 'Poppins',
      fontSize: "16px",
      backgroundColor: "#f0f5f7",
      marginBottom: "2px",
      marginRight: "5px",
      paddingRight: "30px",
      appearance: 'none',
      WebkitAppearance: 'none',
      MozAppearance: 'none',
    }}>
    <option value="apple">Name</option>
    <option value="banana">Name2</option>
    <option value="orange">Name3</option>
  </select>
  
  <span style={{
      position: 'absolute',
      top: '50%',
      right: '10px',
      transform: 'translateY(-50%)',
      pointerEvents: 'none',
      fontSize: '20px',
      color: '#237182',
    }}>
   <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.2292 7.50155L9.99583 10.7349L6.7625 7.50155C6.60681 7.34551 6.39543 7.25781 6.175 7.25781C5.95457 7.25781 5.74319 7.34551 5.5875 7.50155C5.2625 7.82655 5.2625 8.35155 5.5875 8.67655L9.4125 12.5016C9.7375 12.8266 10.2625 12.8266 10.5875 12.5016L14.4125 8.67655C14.7375 8.35155 14.7375 7.82655 14.4125 7.50155C14.0875 7.18488 13.5542 7.17655 13.2292 7.50155Z" fill="#237182"/>
</svg>
  </span>
</Box>
                 
                </Box>
              </Box>
            </Box>
          </Box>
          <Box style={webStyle.mt10}>
            <TableContainer style={webStyle.SubTableBox}>
              {this.state.toggle ? 
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={webStyle.TableTitleText}>
                      Team Name
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleText,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Members
                    </TableCell>
                    <TableCell
                      style={{
                        ...webStyle.TableTitleText,
                        ...webStyle.TableHeaderCenter,
                      }}
                    >
                      Date Created
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                {this.state.rows.length==0 ? <TableRow>
          <TableCell colSpan={12} style={{ textAlign: 'center', fontFamily: 'Poppins' }}>
            No members found
          </TableCell>
        </TableRow>: <TableBody>
                  {this.state.rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell>
                        <Typography style={webStyle.SubBoxy}>
                          {row.name}
                        </Typography>
                      </TableCell>
                      <TableCell style={webStyle.TableHeaderCenter}>
                        <Typography style={webStyle.SubBoxy}>
                          {row.members}
                        </Typography>
                      </TableCell>
                      <TableCell style={webStyle.TableHeaderCenter}>
                        <Typography style={webStyle.SubBoxy}>
                          {row.dataTime}
                        </Typography>
                      </TableCell>
                      <UpdatedBox>
                        <TableCell
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <Typography
                            data-test-id="icon"
                            className="hoverEffect"
                            onClick={(event) => this.handleClick(event, row)}
                          >
                            <MoreVertIcon style={{ cursor: "pointer", color:'#334155' }}/>
                          </Typography>

                          <Popover
                            style={webStyle.popup}
                            open={Boolean(this.state.anchorEl)}
                            anchorEl={this.state.anchorEl}
                            onClose={this.handleClose}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                            PaperProps={{
                              style: {
                                padding: "10px",
                                width: "213px",
                                boxShadow: "0px 4px 8px 0px #00000008",
                                marginLeft: "-3.5rem",
                                borderRadius: "8px",
                              },
                            }}
                          >
                            <MenuItem
                              style={{
                                ...webStyle.popMenu,
                                marginBottom: "10px",
                              }}
                              data-test-id="edit"
                            >
                              {" "}
                              <span style={webStyle.iconM}>
                                <EditIcon />
                              </span>{" "}
                              Edit Team
                            </MenuItem>
                            <MenuItem
                              style={{
                                ...webStyle.popMenu,
                                marginBottom: "10px",
                              }}
                              data-test-id="rename"
                              onClick={() => this.handleRenameModal()}
                            >
                              <span style={webStyle.iconM}>
                                <img src={rename} alt="rename" />
                              </span>{" "}
                              Rename Team
                            </MenuItem>
                            <MenuItem
                              style={webStyle.deleteIcon}
                              data-test-id="delete"
                              onClick={this.handleDeleteModal}
                            >
                              <span style={webStyle.iconM}>
                                <DeleteIcon />
                              </span>{" "}
                              Delete
                            </MenuItem>
                          </Popover>
                        </TableCell>
                      </UpdatedBox>
                    </TableRow>
                  ))}
                </TableBody> }
              </Table>
              :
              <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={webStyle.TableTitleText}>Name</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Email</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Teams</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Role</TableCell>
                  <TableCell style={webStyle.TableTitleText}>Status</TableCell>
                  
                </TableRow>
              </TableHead>
              <TableBody>
               {this.renderMembersList()}
              </TableBody>
            </Table>}
            </TableContainer>
          </Box>
     <Box
      sx={webStyle.footer}
    >
      <Box
      sx={webStyle.footer2}
      >
        <Box
        style={webStyle.footer3}
        >
          <ChevronLeftIcon style={webStyle.footerIcon} />
          <Typography style={webStyle.footerFont}>1</Typography>
          <ChevronRightIcon style={webStyle.footerIcon} />
        </Box>
        <Typography >
              <Typography style={webStyle.footer3Style}>
                1 -{" "}
                {this.state.toggle
                  ? this.state.rows.length
                  : this.state.membersList.length}{" "}
                of{" "}
                {this.state.toggle
                  ? this.state.rows.length
                  : this.state.membersList.length}{" "}
                results
              </Typography>
            </Typography>
      </Box>
    </Box>
       {this.renderCreateTeamModal()}

     {this.renderRenameTeamModal()}
          
       {this.renderDeleteTeamModal()}
          <CustomToast icon={<img src={success} />} />
        </MyProfileHeader>
      </ThemeProvider>
    );
  }
}


const CustomListItem = styled(MenuItem)({
  '&.Mui-selected': {
    backgroundColor: 'transparent',
  },
  '&.Mui-selected:hover': {
    backgroundColor: '#E0EDF0',
  },
  '&:hover': {
    backgroundColor: '#E0EDF0',
  },
  '&.MuiMenuItem-root':{
    paddingLeft:"0px",
    paddingRight: "8px"
  }
});

const CustomCheckbox = styled(Select)({
  borderRadius:'8px',
  height: "32px",
  minWidth:"123px",
  backgroundColor:'#fff',
  "&:hover":{
  backgroundColor: "#fff"
  },
  "& .MuiSvgIcon-root":{
    marginRight: '7px',
    height: '20px',
    width: "20px",
    cursor: 'pointer'
  },
  "& .MuiOutlinedInput-input":{
    padding:"7.5px 14px"
  }
});

const UpdatedBox = styled(Box)({
  "& .hoverEffect": {
    padding: "3px",
    "&:hover": {
      backgroundColor: "#D3E4E9",
      borderRadius: "50%",
    },
  },
});

const CustomToast = styled(ToastContainer)({
  width: "40vw",
  "& .Toastify__toast": {
    boxShadow: "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008",
    padding: "0.5rem 1rem",
    borderRadius: "8px",
  },
  "& .Toastify__toast-body": {
    fontFamily: "Poppins",
    color: "#0F172A",
    padding: 0,
  },
});

const webStyle = {
  OptionBox: {
    display: "flex",
    justifyContent: "start",
  },
  singleOptionBox: {
    display: "flex",
    backgroundColor: "#F8FAFC",
    borderRadius: "50px",
    padding: "4px",
  },
  personInfoDetailBox: {
    display: "flex",
    gap: "24px",
  },
  PersonalInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    fontFamily: "Poppins",
    fontWeight: 600,
    cursor: "pointer",
    fontSize: "16px",
    color:'#325962'
  },
  BusinessInformationOption: {
    padding: "10px 12px 10px 12px",
    borderRadius: "50px",
    cursor: "pointer",
    fontFamily: "Poppins",
    fontWeight: 600,
    lineHeight: "27px",
    margin: "0px",
    fontSize: "16px",
    color:'#64748B',
    ...(window.innerWidth < 600 && {
      textAlign: "center",
    }),
  },
  flex: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 40px 0px 40px",
    marginTop: "32px",
    backgroundColor: "#f0f5f7",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  createTeamBtn: {
    backgroundColor: "#237182",
    color: "white",
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "16px",
    borderRadius: "8px",
    padding: "10px 16px 10px 16px",
    textTransform: "capitalize",
  },
  searchBox: {
    display:'flex'
  },
  sort: {
    marginRight: "3px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color: "#334155",
  },
  mt10: {
    marginTop: "20px",
    paddingLeft: "40px",
    paddingRight: "40px",
  },
  teamSize: {
    marginRight: "15px",
    color: "#475569",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  SubTableBox: {
    borderRadius: "16px ",
    padding: "0px",
    alignItems: "center",
    gap: "10px",
    paddingBottom: "0px",
    fontSize: "16px",
    fontWeight: 400,
    backgroundColor: "#FFFFFF",
  },
  TableTitleText: {
    fontWeight: 600,
    fontSize: "14px",
    color: "#475569",
    fontFamily: "Poppins",
    lineHeight: "22px",
    width: "33%",
  },
  SubDivC:{
    fontWeight: 400,
    fontSize: "14px",
    color: "#1E293B",
    fontFamily: "Poppins",
    lineHeight: "22px",

  },
  TableHeaderCenter: {
    textAlign: "center" as "center",
  },
  TableContentBox: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },

  SubBoxy: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    color:'#1E293B'
  },

  SubUserImg: {
    width: "32px",
    height: "32px",
    borderRadius: "50%",
  },
  SubDivA: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "start",
  },

  SubDivI: {
    fontWeight: 400,
    fontSize: "14px",
    fontFamily: "Poppins",
    padding: "2px 8px",
    borderRadius: "40px",
  },
  deleteIcon: {
    color: "#DC2626",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
  },
  iconM: {
    marginRight: "10px",
    fontSize: "10px",
  },
  popMenu: {
    marginBottom: "10px",
    fontSize: "14px",
    fontFamily: "Poppins",
    fontWeight: 400,
    color:'#0F172A'
  },
  popup: {
    padding: "20px !important",
    marginLeft: "-5px !important",
  },
  createIcon: {
    marginRight: "10px",
  },
  footer: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '20px 40px', 
    boxShadow: 1,
  },
  footer2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerIcon: {
    width: "20px",
    height: "20px",
  },
  footerFont: {
    fontFamily:'Poppins',
    fontSize: "12px",
    fontWeight: 700,
    color: "#237182",
  },
  footer3: {
    display: 'flex',
    alignItems: 'center',
    flex:1,
    justifyContent:'center',
    marginLeft:'35px',
    color: '#94A3B8'
  },
  footer3Style: {
    fontFamily:'Poppins',
    fontWeight: 400,
    fontSize: "12px",
    color: "#64748B",
  },

  renameModal: {
    margin: "auto",
    width: "40vw",
    height: "fit-content",
    padding: "2.5rem 2rem",
    boxShadow:
      "0px 8px 32px 0px #0000000F, 0px 4px 8px 0px #00000008, 0px 25px 50px 0px #00000017",
    background: "white",
    borderRadius: "16px",
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    color: "#0F172A",
    alignItems: "center",
    marginBottom: "1rem",
  },
  renameText: {
    fontWeight: 700,
    fontSize: "1.5rem",
    lineHeight: "2rem",
  },
  renameInputLabel: {
    fontSize: "0.875rem",
    fontWeight: 600,
    color: "#334155",
    lineHeight: "1.375rem",
  },
  errorText: {
    fontSize: "0.75rem",
    color: "#DC2626",
    lineHeight: "1rem",
    fontFamily:"Poppins"
  },
  modalButtons: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: "1rem",
    marginTop: "2rem",
  },
  cancelButton: {
    background: "#E0EDF0",
    color: "#325962",
  },
  clearAll: {
    textAlign: "center" as "center",
    color: "#237182",
    fontWeight: 600,
  }
};

// Customizable Area End
