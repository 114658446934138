// Customizable Area Start
export const filterIcon = require("../assets/filter.svg");
export const backIcon = require("../assets/backIcon.svg");
export const mockIcon = require("../assets/mock_icon.png");
export const file_upload = require("../assets/file_upload.png");
export const buildNewIcon = require("../assets/build_new_icon.png");
export const extendIcon = require("../assets/extend_icon.png");
export const remodelIcon = require("../assets/remodel_icon.png");
export const refurbishIcon = require("../assets/refurbish_icon.png");
export const addMember = require("../assets/add-member.png");
export const addProjectFrame = require("../assets/Frame.svg");
// Customizable Area End
