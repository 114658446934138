// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import { toast } from "react-toastify";
export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface RowData {
  id:string | number;
  attributes:{
    first_name: string;
    last_name:string;
    members: number;
    dataTime: string;
  }
 
}
interface SearchList {
  attributes: any;
  id: string | number;
  user_name: string;
  email: string;
  teams: string;
  role: string;
  status: string;
}

interface MembersList {
  attributes: any;
  id: string | number;
  user_name: string;
  email: string;
  teams: string;
  role: string;
  status: string;
}
interface S {
  messageToggle: boolean;
  tostifymessage: string;
  status: string;
  loading: boolean;
  isAddMemberModalOpen: boolean;
  users: any;
  inputName: string;
  selectedMembers: any;
  newValue: string;
  anchorEl: any;
  filteredUsers: any;
  selectedUsers: any;
  teamName: string;
  teamId:number;
  memberError:string;
  rows: RowData[];
  membersList: MembersList[];
  searchList:SearchList[];
  isRoleOpen: boolean;
  selectedRoles: string[];
  isTeamOpen: boolean;
  selectedTeam: string[];
  isApproveOpen: boolean;
  activated: string[];
  isRenameModalOpen: boolean;
  selectedRow: null | RowData ;
  isAddNot:boolean;
  selectedOrder:string[];
  isDeleteMemberModalOpen:boolean;
  teamNameRenameError:boolean;
  teamRenameBlankError:boolean;
teamRename:string;
historyLocationState:string;
}

interface SS {
  id: any;
}



export default class AddNewMemberController extends BlockComponent<
  Props,
  S,
  SS
> {
  getMembersListCallId: string = "";
  postCreateMemberCallId:string = "";
  getMembersListSelectCallId:string="";
  editTeamNameCallId:string="";
  deleteRemoveMemberCallId:string="";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    this.state = {
      messageToggle: true,
      tostifymessage: "",
      status: "",
      loading: false,
      isAddMemberModalOpen: false,
      inputName: "",
      selectedMembers: [],
      newValue: "",
      anchorEl: null,
      teamName: "",
      users: [],
      filteredUsers: [],
      selectedUsers: [],
      teamId:0,
      memberError:"",
      rows: [],
      membersList: [],
      isRoleOpen: false,
      selectedRoles: [],
      isTeamOpen: false,
      selectedTeam: [],
      isApproveOpen: false,
      activated: [],
      isRenameModalOpen: false,
      selectedRow:null,
      searchList:[],
      isAddNot:false,
      selectedOrder:[],
      isDeleteMemberModalOpen:false,
      teamNameRenameError:false,
      teamRenameBlankError:false,
      teamRename:"",
      historyLocationState:""
      
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }


  async componentDidMount() {
    this.setState({
      historyLocationState : this.props.navigation?.getParam("title" )
    })
    const id = this.props.navigation?.getParam("id") 
    const successData =await getStorageData("successData")

    if (this.state.historyLocationState) {
      this.setState({
        teamName: this.state.historyLocationState,
      });
    }
    if(id){
      this.setState({
        teamId:id
      })
    }
    if(successData){
      this.setState(
        {
          messageToggle: true,
          tostifymessage:"You’ve created a new team, start adding members to it.",
          status: "success",
        },
        () => {
          removeStorageData("successData") 
          this.closeMessageBox();  
        }
      );} 
      else if (successData == null){
        this.setState({
          messageToggle:false
        })
      }
     await  this.getMembersListApi();
     await this.getMembersListSelectApi()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Received", message);

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiCallId === this.getMembersListCallId) {
        this.handleMembersListResponse(responseJson);
      }
      if (apiCallId === this.postCreateMemberCallId) {
        this.handleCreateMemberResponse(responseJson);
      }
      if(apiCallId === this.getMembersListSelectCallId){
        const formatedData = responseJson.data.map(
          (member: any, i: number) => ({
            id: `${i}`,
            ...member,
          })
        );

        this.setState({
          membersList: formatedData,
          searchList:formatedData,
          loading: false,
      
        }); 

      }
      if(apiCallId === this.deleteRemoveMemberCallId){
        this.handleRemoveMemberResponse(responseJson)
      }
      if(apiCallId === this.editTeamNameCallId){
        this.handleEditTeamResponse(responseJson);
      }
    }
  }


 async handleEditTeamResponse(responseJson: any) {
    if (responseJson.data) { this.props.navigation.navigate("AddNewMember",{title:this.state.teamRename , id:this.state.teamId } ,{title:this.state.teamName ,id:this.state.teamId })
      this.setState({ messageToggle: true,
        tostifymessage: "The team has been successfully renamed.",
        status: "success",
        historyLocationState:this.state.teamRename,
        teamName:this.state.teamRename,
      });

      setTimeout(() => {  this.setState({ messageToggle: false, tostifymessage: "", status: "" });
        }, 3000);
      this.handleRenameModal();
      this.handleClose();
       }else if(responseJson.errors == "Team name can't be blank"){
      this.setState({teamRenameBlankError : true  , teamNameRenameError :false });
      }else if(responseJson.errors == "Team name already exists"){
        this.setState({teamRenameBlankError:false ,teamNameRenameError : true  });
      }
  }

filterAvailableUsers(users: Array<any>, selectedUsers: Array<any>, inputName: string): Array<any> {
  return users.filter(
    (user) =>
      user.user_name.toLowerCase().includes(inputName.toLowerCase()) && 
      !selectedUsers.some((selectedUser) => selectedUser.value === user.user_name)
  );
}


  getMembersListSelectApi = async(search = "")=>{
    if(search != ""){
    }
      const token = await getStorageData("token");
      const header = {
        "Content-Type": "Application/json",
        token: token,
      };
  
      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
      this.getMembersListSelectCallId = requestMsg.messageId;

      if(search != ""){
        this.setState({
          isAddNot:true
        })
      }

      const roleFilterQuery = this.state.selectedRoles
      .map((role) => `&role=${role.toLowerCase()}`)
      .join("");

      
      const jobFilterQuery = this.state.selectedTeam
      .map((job) => `&job_title=${job.toLowerCase()}`)
      .join("");

      const activateFilterQuery = this.state.activated
      .map((activate) =>{ 
        if(activate == "Activated"){
       return `&status=active`
      }else{
       return `&status=inactive`
      }})
      .join("");
      
      const sortFilterQuery = this.state.selectedOrder
      .map((sort) => `&sort=${sort.toLowerCase()}`)
      .join("");
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getMembersListSelectEndPoint}/${this.state.teamId}/list_members?search=${search}${roleFilterQuery}${jobFilterQuery}${activateFilterQuery}${sortFilterQuery}`
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMsg.id, requestMsg);
  
      return true;
    
  }

  getMembersListApi = async () => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": "Application/json",
      token: token,
    };

    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    this.getMembersListCallId = requestMsg.messageId;


    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.GetMembersListEndPoint}/list_all_members`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  handleMembersListResponse = (responseJson: any) => {
    if (!responseJson.errors) {
      this.setState({
        users: responseJson.members,
      });
    } else {
      this.setState({
        users: [],
      });
    }
  };

  closeMessageBox = () => {
    setTimeout(() => {
      this.setState({ messageToggle: false});
    
    }, 2000);
  };

  backBtn = () => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "TeamList");
    this.send(msg);
  };

  handleOpenModal = () => {
    this.getMembersListApi();
    this.setState({
      isAddMemberModalOpen: true,
    });
  };

  handleAddMemberModal = () =>
    this.setState((prev) => ({
      isAddMemberModalOpen: !prev.isAddMemberModalOpen,
    }));

  handleMemberAdd = (user: any) => {
    this.setState((prevState) => ({
      selectedUsers: [
        ...prevState.selectedUsers,
        { id:user.id, value: user.user_name, label: user.user_name },
      ],
    }));
  };

   filterUsers = (users: any, selectedUsers: any) => {
    return users
      .filter(
        (user: { user_name: any; }) =>
          !selectedUsers.some(
            (selectedUser: { value: any; }) => selectedUser.value === user.user_name
          )
      )
      .map((user: { user_name: any; }) => ({
        value: user.user_name,
        label: user.user_name,
      }));
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputName: inputValue });
  };

  handleAddMemberData = async()=>{
    const data = this.state.selectedUsers.map((obj: any) => parseInt(obj.id)); 
      const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.ContentType,
        token: token,
      };
     
      const body = {    
         account_ids:data
      }
     
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.postCreateMemberCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.postCreateMemberEndPoint}/${this.state.teamId}/add_members`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
  handleCreateMemberResponse = (responseJson:any)=>{
       if(!responseJson.error){
        if (responseJson.message == "No members added"){
        }
        this.setState({
          isAddMemberModalOpen:false,
          selectedMembers:[],
          selectedUsers:[] , 
         
            messageToggle: true,
            tostifymessage:"New members added to the team.",
            status: "success",  
        })
        this.getMembersListSelectApi()
        setTimeout(() => {
          this.setState({ messageToggle: false });
        }, 2000);
    
       }else{
       if(responseJson.error == "No members selected for addition"){
           this.setState({
            memberError:responseJson.error
           })
       }else if(responseJson.error == "Account must exist"){
        this.setState({
          memberError:"Account must exist"
         })
       }
       }
  }

  handleChange = (
    event: React.ChangeEvent<{ value: unknown; name?: string }>
  ) => {
    const { name, value } = event.target;
    const isClear = (value as string[]).includes("clear");
    if (name === 'role'){
      this.setState(
        {
          selectedRoles: isClear ? [] : value as string[],
          isAddNot:true
        },
        this.getMembersListSelectApi

      );
    } else if(name == 'job'){
      this.setState({
        selectedTeam: value as string[],
        isAddNot:true
      },
      this.getMembersListSelectApi
    );
  
    } else if(name == 'activated'){
      this.setState({
        activated: value as string[],
        isAddNot:true
      },
      this.getMembersListSelectApi
    );
    }else if (name === "sort") {
      
      this.setState(
        {
          selectedOrder: [value as string],  
          isAddNot: true,
        },
        this.getMembersListSelectApi 
      );
    }
  };

  handleClick = (event: React.MouseEvent<HTMLElement>, row: RowData) => {
    console.log(row)
    this.setState({ anchorEl: event.currentTarget, selectedRow: row });
    console.log(this.state.selectedRow)
  };
  
  handleClose = () => {
    this.setState({ anchorEl: null, selectedRow: null });
  };
    handleRenameModal = () => {

      this.setState((prev) => ({
        isRenameModalOpen: !prev.isRenameModalOpen,
        teamRename: prev.teamName,
        teamNameRenameError: false,
        teamRenameBlankError:false
      }));
    };

  handleTeamNameChange=(newName:string)=>{
    this.setState({ teamRename: newName });
  }

  editTeamNameApi = async() => {
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));

    const body = {
      data: {
        attributes: {
          team_name: this.state.teamRename,
        },
      },
    };

    this.editTeamNameCallId = requestMsg.messageId;

    requestMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.teamEndPoint}/${this.state.teamId}`
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );

    requestMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.put
    );

    runEngine.sendMessage(requestMsg.id, requestMsg);

    return true;
  };

  getRoleStyles(role: string) {
    let backgroundColor;
    switch (role) {
      case 'owner':
        backgroundColor = '#237182';
        break;
      case 'employee':
        backgroundColor = '#C5E0E7';
        break;
      default:
        backgroundColor = '#E0EDF0';
    }
    const color = role === 'manager' ? '#237182' : '#fff';
    return { backgroundColor, color };
  }

  handleRemoveMember= ()=>{
    console.log(this.state.selectedRow)
    this.setState((prev) => ({
      isDeleteMemberModalOpen: !prev.isDeleteMemberModalOpen,
      anchorEl: null
    }));
  }

  handleRemoveMemberApi= async()=>{
    console.log("worked")
    const token = await getStorageData("token");      
    const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      const requestMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
  
      this.deleteRemoveMemberCallId = requestMsg.messageId;
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.removeMemberEndPoint}/${this.state.teamId}/remove_member/${this.state.selectedRow?.id}`
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.delete
      );
  
      runEngine.sendMessage(requestMsg.id, requestMsg);
  
      return true;
    
  }

  handleRemoveMemberResponse = (responseJson:any)=>{
      console.log("======>>>",responseJson)
      if(responseJson.message){
        console.log("eworked")
      toast.success(
        `The team member has been successfully removed.`,
        {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          closeButton: false,
          theme: "light",
        }
      );
      this.setState({
        isDeleteMemberModalOpen:false
      })
      this.getMembersListSelectApi()
    }
  }
}

// Customizable Area End
