// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import {
  getStorageData,
  removeStorageData,
} from "../../../framework/src/Utilities";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
const { NavigationActions, StackActions } = require("react-navigation");

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

interface S {
  isModalVisible: boolean;
  filterValue: string;
  isDeleteModalOpen: boolean;
  token: string;
  searchInputText: string;
  deleteProjectID: string;
  isFilterMenuOpen: Element | null;
  isDialogOpen: boolean;
  loading: boolean;
  pageNumber: number | null;
  anchorEl: any;
  cost: string;
  title: string;
  open: false;
  location: string;
  projects: {
    id: string | number;
    title: string;
    location: string;
    cost: string;
  }[];
  activeTab: number;
  specialistCostPlan: boolean;
  sortType: { label: string; value: string }[];
  sortTypeValue: string;
}

interface SS {
  id: any;
}

export default class ProjectPortfolioController extends BlockComponent<
  Props,
  S,
  SS
> {
  apiGetProjectsListCallId: string | null = "";
  apiDeleteProjectsListCallId: string | null = "";

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      isModalVisible: false,
      filterValue: "All Projects",
      isDeleteModalOpen: false,
      token: "",
      searchInputText: "",
      deleteProjectID: "",
      isFilterMenuOpen: null,
      isDialogOpen: false,
      loading: false,
      pageNumber: 1,
      anchorEl: null,
      cost: "",
      title: "",
      open: false,
      location: "",
      activeTab: 0,
      projects: [],
      specialistCostPlan: false,
      sortType: [
        { label: "Date", value: "date" },
        { label: "Time", value: "time" },
      ],
      sortTypeValue: "date",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  handleCreateNewProject=()=>{
    const message: Message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "ProjectPlan");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message); 

  }
  handleSortByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ sortTypeValue: event.target.value });
  };

  handleToggleChange = () => {
    this.setState((prevState) => ({
      specialistCostPlan: !prevState.specialistCostPlan,
    }));
  };

  handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ activeTab: newValue });
  };

  handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const errorJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.apiGetProjectsListCallId: {
          this.getProjectListHandleResponse(responseJson);
          break;
        }
      }
    }
  }

  async componentDidMount() {
    const token = await getStorageData("token");
    this.setState({ token });
    this.getProjectsListData();
  }

  getProjectListHandleResponse = (responseJson: any) => {
    const projects = responseJson.data.map((project: any) => ({
      id: project.id,
      title: project.attributes.description,
      location: project.attributes.addressable.city,
      cost: "N/A",
    }));
    this.setState({ projects, loading: false });
  };

  getProjectsListData = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: this.state.token,
    };

    this.setState({ loading: true });

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetProjectsListCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllProjectsAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAPiEndMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearch(text: string) {
    this.getProjectsListData();
    this.setState({ searchInputText: text });
  }

  changeApi(type: string) {
    this.setState(
      {
        filterValue: type,
        isModalVisible: false,
        projects: [],
        searchInputText: "",
        pageNumber: 1,
      },
      () => {
        this.getProjectsListData();
      }
    );
  }

  goBack() {
    const message: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  }

  openFilterMenu() {
    this.setState({ isModalVisible: true });
  }

  deleteModal(projectID: string) {
    this.setState({ isDeleteModalOpen: true, deleteProjectID: projectID });
  }

  closeDeleteModal() {
    this.setState({ isDeleteModalOpen: false });
  }

  filterModal() {
    this.setState({ isModalVisible: false });
  }

  naviagteToCreatePage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "ProjectPlan"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message);
  };

  naviagteToEditPage = (projectId: string) => {
    const message: Message = new Message(
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData("Dummy text because Enum does not exist", projectId);
    this.send(message);
  };

  naviagteToViewPage = (projectId: string) => {
    const message: Message = new Message(
      "Dummy text because Enum does not exist"
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData("Dummy text because Enum does not exist", projectId);
    this.send(message);
  };
  loadMoreData = () => {
    if (this.state.pageNumber !== null) {
      this.setState({
        pageNumber: Number(this.state.pageNumber) + 1,
      });
    }
  };
  logOutFunction = () => {
    removeStorageData("token");
    this.props.navigation.dispatch(
      StackActions.reset({
        index: 0,
        actions: [
          NavigationActions.navigate({
            routeName: "EmailAccountLoginScreen",
          }),
        ],
      })
    );
  };
}
// Customizable Area End
